const state = {};

const mutations = {};

const actions = {
  async outputImage({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.outputImage, data);

    return res;
  },
  async reportList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.report, {
      params: data,
    });

    return res;
  },
  async reportDelete({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.reportDetelte, data);

    return res;
  },
  async reportHistoryList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.reportHistory, {
      params: data,
    });

    return res;
  },
  async reportHistoryDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.reportHistoryDetail, {
      params: data,
    });

    return res;
  },
  async reportUpload({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.reportUpload, data);

    return res;
  },
  async reportCheck({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.reportCheck, data);

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
