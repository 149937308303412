/*
 * @Author: Eric
 * @Date: 2024-04-02 16:11:43
 * @LastEditors: Eric
 * @LastEditTime: 2024-05-11 11:22:55
 * @Description: file content
 */
const state = {};

const mutations = {};

const actions = {
  async surveyList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.surveyList, {
      params: data,
    });
    return res.data;
  },
  async surveyView({ rootState, commit, state }, data) {
    let path = rootState.api.surveyList + "/" + data.id;
    if (data.param && data.param.answerId) {
      path = `${rootState.api.surveyList}/${data.id}/answer/${data.param.answerId}`;
    }
    let res = await rootState.axios.get(path, {});
    return res.data;
  },
  async addSurvey({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.surveyList, data);
    return res.data;
  },
  async editQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id,
      data
    );
    return res.data;
  },
  async isAnswerEditQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/answered/" + data.id,
      data
    );
    return res.data;
  },
  async offlineQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/offline",
      data
    );
    return res.data;
  },
  async onlineQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/online",
      data
    );
    return res.data;
  },
  async turnoffQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/turn-off",
      data
    );
    return res.data;
  },
  async turnonQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/turn-on",
      data
    );
    return res.data;
  },

  async surveyDelete({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(
      rootState.api.surveyList + "/" + data
    );
    return res.data;
  },
  async surveyCascade({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.surveyList + "/cascade", {
      params: data,
    });
    return res.data;
  },
  async getSurveyCascade({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/cascade/" + data.code,
      {
        params: data,
      }
    );
    return res.data;
  },
  async surveyAnswer({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/" + data.id + "/answer",
      {
        params: data,
      }
    );
    return res.data;
  },
  async surveyAnswerExport({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/" + data.id + "/answer/export",
      {
        responseType: "blob",
      }
    );
    return res.data;
  },
  async surveyAnswerId({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/" + data.id + "/answer/" + data.answerId,
      {
        params: data,
      }
    );
    return res.data;
  },
  async copySurvey({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      `${rootState.api.surveyList}/copy/${data}`
    );
    return res.data;
  },
  async copySurveyToTemplate({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      `${rootState.api.templateList}/save/${data}`
    );
    return res.data;
  },
  async surveyQrcode({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      `${rootState.api.surveyList}/qrcode/${data}`
    );
    return res.data;
  },
  async cascadSelct({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyH5 + "/cascade/" + data,
      {}
    );
    return res.data;
  },
  async empSelct({ rootState, commit, state }) {
    let res = await rootState.axios.get(
      rootState.api.surveyH5 + "/cascade/all",
      {}
    );
    return res.data;
  },
  async cascadeImport({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      `${rootState.api.surveyList}/cascade/import/${data.name}`,
      data.params
    );
    return res.data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
