<template>
  <div class="frontDropDown" :class="{ selected: showHideStatus }">
    <section class="frontDropDown-box" @click="showHide">
      <div
        v-if="
          (activeName && activeName.length > 0) ||
          (activeValue && activeValue.length > 0)
        "
        class="frontDropDown-box__line"
      >
        <div
          class="frontDropDown-box__more"
          v-if="dataType && dataType === 'array'"
        >
          <ul>
            <li
              v-for="(item, index) in activeName || activeValue"
              :key="'actName' + index"
              v-show="index < 3"
            >
              <span class="frontDropDown-box__more__word">{{ item }}</span>
              <span
                class="frontDropDown-box__more__close"
                @click="deleteItem(item, index)"
              ></span>
            </li>
            <li class="lastChild" v-show="activeName && activeName.length > 3">
              ...
            </li>
          </ul>
        </div>

        <span class="frontDropDown-box__single" v-else>{{
          activeName || activeValue
        }}</span>
      </div>

      <div class="frontDropDown-box__line" v-else>
        <span class="frontDropDown-box__placeholder">{{
          $t("home.selectPlace")
        }}</span>
      </div>
    </section>

    <section class="frontDropDown-content" v-if="showHideStatus" @click="show">
      <slot name="content" />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "frontDropDown",
  props: ["activeValue", "activeName", "dataType", "indexInfo"],
  data() {
    return {
      showHideStatus: false,
    };
  },
  computed: {
    ...mapState({
      dropDownTotalStatus: (state) => state.login.dropDownTotalStatus,
    }),
  },
  watch: {
    dropDownTotalStatus(val) {
      if (!val) {
        setTimeout(() => {
          this.showHideStatus = false;

          setTimeout(() => {
            this.$store.commit("login/set_dropDownTotalStatus", true);
          }, 0);
        }, 0);
      }
    },
  },
  methods: {
    showHide(e) {
      e.stopPropagation();

      if (!this.showHideStatus) {
        this.$store.commit("login/set_dropDownTotalStatus", false);

        setTimeout(() => {
          this.showHideStatus = true;
        }, 10);
      }
    },
    show(e) {
      e.stopPropagation();
      this.showHideStatus = true;
    },
    deleteItem(item, index) {
      let params = {
        data: this.activeValue[index],
        index: index,
        indexInfo: this.indexInfo,
      };

      this.$emit("delete", params);
    },
  },

  mounted() {
    let _this = this;
    $(document).on("click", function () {
      _this.showHideStatus = false;
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.frontDropDown {
  position: relative;
  display: inline-block;
  height: toPad(38);

  .frontDropDown-box {
    position: relative;

    width: 100%;
    height: toPad(38);

    font-size: toPad(15);

    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: toPad(6);
    cursor: pointer;

    .frontDropDown-box__line {
      position: relative;
      display: flex;
      width: 100%;
      height: toPad(38);
      align-items: center;
      padding: 0 toPad(40) 0 toPad(7);
    }

    &::after {
      display: inline-block;
      content: "";
      position: absolute;
      z-index: 1;
      width: toPad(12);
      height: toPad(9);
      background: url("~@/src/assets/common/select-icon.png") no-repeat center
        center;
      background-size: auto 100%;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .frontDropDown-content {
    display: none;
    position: absolute;
    width: 100%;
    min-height: toPad(50);
    border-left: 1px solid #3e886d;
    border-right: 1px solid #3e886d;
    border-bottom: 1px solid #3e886d;
    border-radius: 0 0 toPad(6) toPad(6);
    z-index: 5;
    left: 0;
    top: 100%;
    background: #fff;
    overflow: auto;
  }

  &.selected {
    .frontDropDown-box {
      border-left: 1px solid #3e886d !important;
      border-right: 1px solid #3e886d !important;
      border-top: 1px solid #3e886d !important;
      border-radius: toPad(6) toPad(6) 0 0;
    }
    .frontDropDown-content {
      display: block;
    }
  }

  .frontDropDown-box__placeholder {
    display: inline-block;
    max-width: 100%;
    line-height: 1.2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: toPad(11);
    color: #bbb;
  }
  .frontDropDown-box__single {
    display: inline-block;
    max-width: 100%;
    line-height: 1.2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: toPad(11);
  }
  .frontDropDown-box__more {
    width: 100%;
    overflow: hidden;

    ul {
      display: flex;
      width: 100%;
      height: toPad(28);
      overflow: hidden;

      li {
        position: relative;
        display: inline-flex;
        padding: 0 toPad(20) 0 toPad(10);
        background: #e5e9e9;
        border-radius: 3px;
        margin-right: toPad(10);
        align-items: center;
        font-size: toPad(12);
        flex-shrink: 0;
        max-width: 25%;

        .frontDropDown-box__more__word {
          position: relative;
          display: inline-block;
          width: 100%;
          line-height: 1.2;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          top: 1px;
        }

        &:last-child {
          margin-right: 0;
        }
        .frontDropDown-box__more__close {
          position: absolute;
          display: block;
          width: toPad(20);
          height: toPad(20);
          background: url("~@/src/assets/common/close-small.png") no-repeat
            center center;
          background-size: toPad(8) toPad(8);
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }

        &.lastChild {
          background: transparent;
          margin-right: 0;
          padding: 0;

          .frontDropDown-box__more__close {
            display: none;
          }
        }
      }
    }
  }
}
</style>
