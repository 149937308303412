const state = {};

const mutations = {};

let baseApi = process.env.NODE_ENV === "development" ? "api" : "";

const actions = {
  async departList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(baseApi + "/report/dept", {
      params: data,
    });

    return res;
  },
  async departDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data + "/info"
    );

    return res;
  },
  async departMemberRatio({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data + "/emp-portrait"
    );

    return res;
  },
  async departWorkInfo({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data + "/work-info"
    );

    return res;
  },

  async departLoyalty({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data + "/contribution"
    );

    return res;
  },

  async departKpInfo({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data + "/achievements"
    );

    return res;
  },

  async departMemberNum({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data + "/emp-nums"
    );

    return res;
  },

  async departMemberList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data.departmentId + "/emp-list",
      {
        params: data,
      }
    );

    return res;
  },

  async departMemberList_tree({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data + "/emp-tree"
    );

    return res;
  },
  async chartDepartEnps({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data.id + "/enps",
      {
        params: data.params,
      }
    );

    return res;
  },
  async deptPerforDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/dept/" + data.id + "/performance-details",
      {
        params: data.params,
      }
    );

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
