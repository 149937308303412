import CryptoJS from "crypto-js";

let AESKey = "jlrSuperSecretSp";

const utils = {
  stNdTh(data) {
    let result = 0;

    if (data) {
      let dataLs = data + "",
        dataLsAry = dataLs.split(""),
        dataLast = dataLsAry[dataLsAry.length - 1];

      let dataNum = parseInt(data);

      if (dataNum <= 20) {
        if (dataNum == 1) {
          result = data + "st";
        } else if (dataNum == 2) {
          result = data + "nd";
        } else if (dataNum == 3) {
          result = data + "rd";
        } else {
          result = data + "th";
        }
      } else {
        if (dataLast == 1) {
          result = data + "st";
        } else if (dataLast == 2) {
          result = data + "nd";
        } else if (dataLast == 3) {
          result = data + "rd";
        } else {
          result = data + "th";
        }
      }
    }

    return result;
  },
  getOsInfo() {
    let os = { name: "Unknown", version: "0" };

    const { userAgent } = window.navigator;
    let macReg = /Mac OS X [0-9_]+/gi,
      iphoneReg = /iPhone OS [0-9_]+/gi,
      ipadReg = /iPad; CPU OS [0-9_]+/gi,
      androidReg = /Android [0-9.]+/gi,
      winReg = /Windows NT [0-9.]+/gi;
    let osMatch = userAgent.match(macReg);
    if (osMatch) {
      const [, , , version] = osMatch[0].split(" ");
      os.name = "Mac";
      os.version = version.replace(/_/gi, ".");
      return os;
    }
    osMatch = userAgent.match(iphoneReg);
    if (osMatch) {
      const [, , version] = osMatch[0].split(" ");
      os.name = "iOS";
      os.version = version.replace(/_/gi, ".");
      return os;
    }
    osMatch = userAgent.match(ipadReg);
    if (osMatch) {
      const [, , , version] = osMatch[0].split(" ");
      os.name = "iPad";
      os.version = version.replace(/_/gi, ".");
      return os;
    }
    osMatch = userAgent.match(androidReg);
    if (osMatch) {
      const [, version] = osMatch[0].split(" ");
      os.name = "Android";
      os.version = version;
      return os;
    }
    osMatch = userAgent.match(winReg);
    if (osMatch) {
      const [, , version] = osMatch[0].split(" ");
      os.name = "Windows";
      os.version = version;
    }
    return os;
  },
  strWrap(data, num) {
    let result = "";

    if (data) {
      let dataAry = data.split("");

      dataAry.forEach((val, e) => {
        if (e % (num - 1) == 0) {
          dataAry.splice(e, 0, "<br/>");
        }
      });

      result = dataAry.join("");
    }

    return result;
  },
  getValueLastWord(data) {
    let result = "";

    if (data) {
      let aryLs = data.split(""),
        aryLength = aryLs.length,
        aryLsLast = aryLs[aryLength - 1];

      result = aryLsLast;
    }

    return result;
  },
  formatMoney(money) {
    if (money !== undefined) {
      const arr = money.toString().split(".");
      return (
        (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") +
        (arr[1] ? "." + arr[1] : "")
      );
    } else {
      return money;
    }
  },
  sercetWaterSign(data) {
    let result = data || "";

    if (data && data.length > 0 && process.env.VUE_APP_MODES != "prod") {
      result = "";

      let resultAry = data.split("");

      resultAry.forEach((val, e) => {
        if (e == 0) {
          result = val;
        } else {
          result += "*";
        }
      });
    }

    return result;
  },
  encryption(value) {
    let key = CryptoJS.enc.Utf8.parse(AESKey);
    let iv = CryptoJS.enc.Utf8.parse(AESKey.substr(0, 16));
    let srcs = CryptoJS.enc.Utf8.parse(value);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  },
  decryption(value) {
    const key = CryptoJS.enc.Utf8.parse(AESKey);
    let iv = CryptoJS.enc.Utf8.parse(AESKey.substr(0, 16));
    const decrypt = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  },
  randomColor() {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);

    return `rgb(${r},${g},${b})`;
  },
  randomColor_depart(index) {
    let colorArray = [
      "#3e886d",
      "#e7c16a",
      "#58c7bd",
      "#f7c85b",
      "#81b5a2",
      "#8abae6",
      "#95c5ae",
      "#caad7f",
      "#6ca1a4",
      "#8abae6",
      "#a4aab0",
      "#bedcf8",
      "#95c5ae",
      "#6ca1a4",
      "#caad7f",
      "#eebb6b",
    ];

    let colorlength = colorArray.length;

    let realIndex = (index + 1) % colorlength;

    return colorArray[realIndex - 1];
  },
  getCookie(params) {
    let cookieArray = "";
    let result = "";

    cookieArray = document.cookie.split("; ");

    let cookieFilterArray = cookieArray.filter((val) => {
      return (
        val.split("=").length > 0 && val.split("=")[0].indexOf(params) != -1
      );
    });

    result =
      cookieFilterArray.length > 0 ? cookieFilterArray[0].split("=")[1] : "";

    return result;
  },
  dateFormat(data, type) {
    let result = "";
    let newDate = new Date(data);

    let month = newDate.getMonth() + 1;
    let date =
      newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();

    let hours = newDate.getHours();

    let hoursRes = hours < 10 ? "0" + hours : hours;
    let minutes =
      newDate.getMinutes() < 10
        ? "0" + newDate.getMinutes()
        : newDate.getMinutes();
    let seconds =
      newDate.getSeconds() < 10
        ? "0" + newDate.getSeconds()
        : newDate.getSeconds();

    let monthStr = "";

    switch (month) {
      case 1:
        monthStr = "January";
        break;
      case 2:
        monthStr = "February";
        break;
      case 3:
        monthStr = "March";
        break;
      case 4:
        monthStr = "April";
        break;
      case 5:
        monthStr = "May";
        break;
      case 6:
        monthStr = "June";
        break;
      case 7:
        monthStr = "July";
        break;
      case 8:
        monthStr = "August";
        break;
      case 9:
        monthStr = "September";
        break;
      case 10:
        monthStr = "October";
        break;
      case 11:
        monthStr = "November";
        break;
      case 12:
        monthStr = "December";
        break;
    }

    if (data) {
      result = date + " " + monthStr;

      if (type) {
        if (type === "timeAll") {
          result = result + " " + hoursRes + ":" + minutes + ":" + seconds;
        }
      }
    }

    return result;
  },
  // 2012-12-12
  dateFormatFn(date, format) {
    if (!date || (typeof date === "string" && isNaN(Date.parse(date)))) {
      return "";
    }
    var d = new Date(date);
    var zeroize = function (value, length) {
      if (!length) length = 2;
      value = String(value);
      for (var i = 0, zeros = ""; i < length - value.length; i++) {
        zeros += "0";
      }
      return zeros + value;
    };
    return format.replace(
      /"[^"]*"|'[^']*'|\b(?:d{1,4}|m{1,2}|M{1,4}|yy(?:yy)?|([hHMstT])\1?|[lLZ])\b/g,
      function ($0) {
        switch ($0) {
          case "d":
            return d.getDate();
          case "dd":
            return zeroize(d.getDate());
          case "ddd":
            return ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"][
              d.getDay()
            ];
          case "dddd":
            return [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ][d.getDay()];
          case "M":
            return d.getMonth() + 1;
          case "MM":
            return zeroize(d.getMonth() + 1);
          case "MMM":
            return [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ][d.getMonth()];
          case "MMMM":
            return [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ][d.getMonth()];
          case "yy":
            return String(d.getFullYear()).substr(2);
          case "yyyy":
            return d.getFullYear();
          case "h":
            return d.getHours() % 12 || 12;
          case "hh":
            return zeroize(d.getHours() % 12 || 12);
          case "H":
            return d.getHours();
          case "HH":
            return zeroize(d.getHours());
          case "m":
            return d.getMinutes();
          case "mm":
            return zeroize(d.getMinutes());
          case "s":
            return d.getSeconds();
          case "ss":
            return zeroize(d.getSeconds());
          case "l":
            return zeroize(d.getMilliseconds(), 3);
          case "L":
            var m = d.getMilliseconds();
            if (m > 99) m = Math.round(m / 10);
            return zeroize(m);
          case "tt":
            return d.getHours() < 12 ? "am" : "pm";
          case "TT":
            return d.getHours() < 12 ? "AM" : "PM";
          case "Z":
            return d.toUTCString().match(/[A-Z]+$/);
          default:
            return $0.substr(1, $0.length - 2);
        }
      }
    );
  },
  getTimeFn(data, type, icon) {
    var date = new Date(data);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();

    var oHour = date.getHours();
    var oMin =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var oSen = date.getSeconds();

    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = "";

    if (type === "date") {
      currentdate =
        year +
        (icon ? icon : "年") +
        month +
        (icon ? icon : "月") +
        strDate +
        (icon ? "" : "日");
    } else if (type === "noYearNoTime") {
      currentdate = month + (icon ? icon : "月") + strDate + (icon ? "" : "日");
    } else if (type === "time") {
      currentdate = oHour + ":" + oMin;
    } else {
      currentdate =
        year +
        (icon ? icon : "年") +
        month +
        (icon ? icon : "月") +
        strDate +
        (icon ? "" : "日") +
        " " +
        oHour +
        ":" +
        oMin;
    }

    return currentdate;
  },
  formatLang(lang, cnVal, enVal) {
    if (lang === "cn") {
      return cnVal || enVal;
    } else {
      return enVal || cnVal;
    }
  },
  getRootSize() {
    return Number(document.documentElement.style.fontSize.replace("px", ""));
  },
  transferPx(px) {
    return (px * 13.66 * utils.getRootSize()) / 1366;
  },
  downloadFile(url, fileName) {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = fileName;
    link.style.display = "none";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};
export default utils;
