<template>
  <div
    class="edpWaterBg"
    :style="
      (opacity ? 'opacity:' + opacity + ';' : '') +
      (zIndex ? 'zIndex:' + zIndex + ';' : '')
    "
  >
    <div
      class="edpWaterBg-waterSign-in"
      v-for="(itemWater, indexWater) in num || 300"
      :key="'water' + indexWater"
      :style="
        (percent ? 'width:' + percent + ';' : '') +
        '' +
        (itemHeight ? 'height:' + itemHeight + ';' : '')
      "
    >
      <span>{{ utils.sercetWaterSign(userInfoMap.cdsid) }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: ["num", "percent", "opacity", "itemHeight", "zIndex"],
  name: "edpWaterBg",
  computed: {
    ...mapState({
      userInfoMap: (state) => state.login.userInfo,
    }),
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edpWaterBg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  color: #ddd;
  z-index: 0;
  opacity: 0.8;
  font-weight: bold;
  overflow: hidden;

  .edpWaterBg-waterSign-in {
    display: flex;
    width: 10%;
    height: toPad(100);
    float: left;
    font-size: toPad(18);

    font-family: JLREmeric;

    justify-content: center;
    align-items: center;

    span {
      transform: rotate(-30deg);
      transform-origin: center center;
    }
  }
}
</style>
