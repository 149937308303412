/*
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-03-02 16:20:41
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-07-07 16:46:58
 * @FilePath: \edp-web\src\store\pages\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const state = {};

const mutations = {};

const actions = {
  async userList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.userList, {
      params: data,
    });
    return res.data;
  },
  async userEdit({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.userList + "/" + data.id,
      data
    );
    return res.data;
  },
  async userSync({ rootState, commit, state }) {
    let res = await rootState.axios.post(rootState.api.userSync);
    return res;
  },
  async userDel({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(rootState.api.userList + "/" + data);
    return res.data;
  },
  async moduleList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.module, {
      params: data,
    });
    return res.data;
  },
  async moduleDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.module + "/" + data);
    return res.data;
  },
  async addModule({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.module, data);
    return res.data;
  },
  async delModule({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(
      rootState.api.module + "/" + data.id
    );
    return res.data;
  },
  async editModule({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.module + "/" + data.id,
      data
    );
    return res.data;
  },
  async functionList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.function, {
      params: data,
    });
    return res.data;
  },
  async functionDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.function + "/" + data);
    return res.data;
  },
  async addFunction({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.function, data);
    return res.data;
  },
  async delFunction({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(
      rootState.api.function + "/" + data.id
    );
    return res.data;
  },
  async editFunction({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.function + "/" + data.id,
      data
    );
    return res.data;
  },
  async deptList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.department, {
      params: data,
    });
    return res.data;
  },
  async setDept({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.department, data);
    return res.data;
  },
  async tempList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.template, {
      params: data,
    });
    return res.data;
  },
  async addTemp({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.template, data);
    return res.data;
  },
  async editTemp({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.template + "/" + data.id,
      data
    );
    return res.data;
  },
  async getDictValue({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.dicType + "/" + data + "/value"
    );
    return res.data;
  },
  async categoryList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.queryCategory + "/tree", {
      params: data,
    });
    return res.data;
  },
  async addCategory({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.queryCategory, data);
    return res.data;
  },
  async editCategory({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.queryCategory + "/" + data.id,
      data
    );
    return res.data;
  },
  async fieldList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.queryField, {
      params: data,
    });
    return res.data;
  },
  async addField({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.queryField, data);
    return res.data;
  },
  async editField({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.queryField + "/" + data.id,
      data
    );
    return res.data;
  },
  async getTable({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.fieldTable, {
      params: data,
    });
    return res.data;
  },
  async getColumn({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.fieldColumn, {
      params: data,
    });
    return res.data;
  },
  async medalList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.medal, {
      params: data,
    });
    return res.data;
  },
  async medalDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.medal + "/" + data);
    return res.data;
  },
  async addMedal({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.medal, data);
    return res.data;
  },
  async delMedal({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(rootState.api.medal + "/" + data);
    return res.data;
  },
  async editMedal({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.medal + "/" + data.id,
      data
    );
    return res.data;
  },
  async recommendList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.recommendList);
    return res.data;
  },
  async crowdList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.crowdList, {
      params: data,
    });
    return res.data;
  },
  async crowdExport({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.crowdExport, data, {
      responseType: "blob",
    });
    return res.data;
  },
  async setCrowd({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.setCrowd + data);
    return res.data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
