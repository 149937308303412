<template>
  <div class="edpBackendUser" ref="headerContent">
    <div class="fjc">
      <router-link to="/">
        <img
          src="@/src/assets/common/logo-group-white.png"
          alt=""
          class="edpBackendUser-logo"
          @click="$router.push({ name: 'home' })"
        />
      </router-link>
      <span class="fac edpBackendUser-text">Employee Data Platform</span>
    </div>
    <div class="edpBackendUser-info">
      <lang-change mode="pc" />
    </div>
  </div>
</template>

<script>
export default {
  name: "edpBackendUser",
  mounted() {
    let _this = this;

    setTimeout(() => {
      let windowHeight = parseInt($(window).height());

      _this.blankHeight = _this.$refs.headerContent.clientHeight + "px";
      _this.$store.commit(
        "login/set_switchContentHeight",
        windowHeight - _this.$refs.headerContent.clientHeight + 1
      );

      _this.$store.commit(
        "login/set_switchContentTop",
        _this.$refs.headerContent.clientHeight - 1
      );
    }, 0);

    $(window).on("resize", function () {
      if (_this.$refs.headerContent) {
        let windowHeight = parseInt($(window).height());

        _this.blankHeight = _this.$refs.headerContent.clientHeight + "px";

        _this.$store.commit(
          "login/set_switchContentHeight",
          windowHeight - _this.$refs.headerContent.clientHeight + 1
        );

        _this.$store.commit(
          "login/set_switchContentTop",
          _this.$refs.headerContent.clientHeight - 1
        );
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edpBackendUser {
  position: relative;
  display: flex;
  width: 100%;
  height: toPc(56);
  justify-content: space-between;
  align-items: center;
  padding: 0 toPc(34);
  z-index: 12;
  background-image: url("../../assets/common/header-bg.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.edpBackendUser-logo {
  display: block;
  width: auto;
  height: toPc(30);
  flex-shrink: 0;
}
.edpBackendUser-text {
  color: #fff;
  margin-left: 25px;
  font-size: 24px;
  font-weight: bold;
}
.edpBackendUser-info {
  flex-basis: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
