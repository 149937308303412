const state = {};

const mutations = {};

const actions = {
  async abilityList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.abilityList, {
      params: data,
    });

    return res;
  },
  async memberReportDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.memberReportDetail + "/" + data
    );

    return res;
  },
  async abilityView({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.abilityView + "/" + data);

    return res;
  },
  async abilityDown({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.abilityDown + "/" + data,
      { params: {} },
      {
        responseType: "blob",
      }
    );

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
