const state = {};

const mutations = {};

const actions = {
  async roleList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.role, {
      params: data,
    });

    return res;
  },
  async roleCreate({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.role, data);

    return res;
  },
  async roleUpdate({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.role + "/" + data.id,
      data
    );

    return res;
  },
  async roleDelete({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(rootState.api.role + "/" + data);

    return res;
  },
  async roleDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.role + "/" + data);

    return res;
  },
  async roleRefresh({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.roleRefresh);

    return res;
  },
  async userPermitListApi({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.userPermitListApi + "/" + data
    );

    return res;
  },
  async userPermitUpdateApi({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.userPermitUpdateApi + "/" + data.id,
      data.params
    );

    return res;
  },
  async userSyncDepartData({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.userSyncDepartData);

    return res;
  },

  async deptPermitListApi({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.deptPermitListApi + "/" + data
    );

    return res;
  },
  async deptPermitUpdateApi({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.deptPermitUpdateApi,
      data.params
    );

    return res;
  },
  async deptUserRptPermitCheck({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.deptUserRptPermitCheck + "/" + data
    );

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
