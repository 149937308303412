<template>
  <div class="edp-header--search">
    <div
      class="edp-header--search__blank"
      ref="headerBlank"
      :style="blankHeight ? 'height:' + blankHeight : ''"
    ></div>

    <div class="edp-header--search__content" ref="headerContent">
      <div class="edp-header--search__content__in">
        <span
          class="edp-header--search__left"
          @click="backClick"
          v-if="headerLeftWord"
        >
          <img src="@/src/assets/common/icon-left-white.png" />
          {{ headerLeftWord }}
        </span>

        <section class="edp-header--search__center">
          <div class="edp-header--search__search" v-if="!noSearch">
            <img src="@/src/assets/common/search-white.png" @click="search" />
            <input
              type="text"
              :placeholder="searchPlaceHolder"
              v-model="searchName"
              v-on:keyup.enter="search"
            />
          </div>

          <img
            v-else
            src="@/src/assets/common/logo-group-white.png"
            @click="goHome"
            class="edp-header--search__logo"
          />
        </section>

        <!--<section class="edp-header--search__noSearch" v-if="noSearch"></section>-->

        <span class="edp-header--search__right"><lang-change /></span>
      </div>

      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["headerLeftWord", "searchPlaceHolder", "backOther", "noSearch"],
  name: "edpHeaderSearch",
  data() {
    return {
      searchName: "",
      blankHeight: 0,
    };
  },
  methods: {
    goHome() {
      if (this.$route.name === "home") {
        this.$store.commit("login/set_switchVisible", false);
      } else {
        this.$router.push({ name: "home" });
      }
    },
    search() {
      let params = {
        searchName: this.searchName,
      };
      this.$emit("search", params);
    },
    backClick() {
      if (this.backOther) {
        this.$emit("back");
      } else {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    let _this = this;

    setTimeout(() => {
      let windowHeight = parseInt($(window).height());

      _this.blankHeight = _this.$refs.headerContent.clientHeight + "px";
      _this.$store.commit(
        "login/set_switchContentHeight",
        windowHeight - _this.$refs.headerContent.clientHeight + 1
      );

      _this.$store.commit(
        "login/set_switchContentTop",
        _this.$refs.headerContent.clientHeight - 1
      );
    }, 0);

    $(window).on("resize", function () {
      if (_this.$refs.headerContent) {
        let windowHeight = parseInt($(window).height());

        _this.blankHeight = _this.$refs.headerContent.clientHeight + "px";

        _this.$store.commit(
          "login/set_switchContentHeight",
          windowHeight - _this.$refs.headerContent.clientHeight + 1
        );

        _this.$store.commit(
          "login/set_switchContentTop",
          _this.$refs.headerContent.clientHeight - 1
        );
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-header--search {
  position: relative;
  width: 100%;
  flex-shrink: 0;

  .edp-header--search__blank {
    width: 100%;
    z-index: 11;
    background: transparent;
    overflow: hidden;
  }
  .edp-header--search__content {
    position: fixed;
    width: 100%;
    font-size: toPad(14);
    box-shadow: 0 toPad(-2) toPad(13) #e6ecf4;
    margin: 0 auto;
    z-index: 12;
    left: 0;
    top: 0;
    background-image: url("../../assets/common/header-bg.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .edp-header--search__content__in {
    position: relative;
    width: 100%;
    padding: toPad(14) toPad(20);
    min-width: 1200px;
  }
}
.edp-header--search__left {
  display: inline-flex;
  position: absolute;
  left: toPad(26);
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  line-height: 1;
  cursor: pointer;
  z-index: 2;
  white-space: nowrap;
  color: #fff;

  img {
    display: block;
    width: auto;
    height: toPad(12);
    margin-right: toPad(12);
  }
}
.edp-header--search__center {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1;

  .edp-header--search__logo {
    display: block;
    box-sizing: content-box;
    width: auto;
    height: toPad(30);
    padding: toPad(5);
    cursor: pointer;
  }
}
.edp-header--search__noSearch {
  position: relative;
  z-index: 0;
  height: toPad(31);
  background: transparent;
}
.edp-header--search__right {
  display: inline-block;
  position: absolute;
  right: toPad(26);
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;
}

.edp-header--search__search {
  position: relative;
  display: flex;
  width: 60%;
  height: toPad(31);
  max-width: toPad(484);
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  background: rgba(217, 217, 217, 0.3);
  color: #fff;
  border-radius: toPad(31);
  overflow: hidden;

  img {
    position: absolute;
    z-index: 2;
    width: toPad(14);
    height: toPad(14);
    left: toPad(11);
    top: 50%;
    transform: translateY(-50%);
  }
  input {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 0 toPad(18) 0 toPad(30);
    border: 0;
    font-size: toPad(14);
    background: transparent;

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }
}
</style>
