<template>
  <section class="anyRating-main-title">
    <!--<i class="el-icon-s-fold anyRating-main-title-titleIcon"></i>-->
    <span class="anyRating-main-title-titleWord" v-if="menuActive.totalCnName && menuActive.totalEnName">{{
      $i18n.locale === "cn" ? menuActive.totalCnName : menuActive.totalEnName
    }}</span>
    <template v-else>
      <slot />
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "mainTitle",
  data() {
    return {
      title: "",
    };
  },
  computed: {
    ...mapState({
      menuActive: (state) => state.backendMenu.menuActive,
    }),
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.anyRating-main-title {
  width: 100%;
  background: #514d50;
  color: #fff;
  padding: toPc(16) toPc(32);
  display: flex;
  align-items: center;
  font-size: 16px;

  i {
    margin-right: toPc(10);
  }
}
</style>
