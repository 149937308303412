const state = {
  userInfo: {
    sessionId: "",
  },
  userName: "",
  page_platform: "",
  lang: "en",
  hasPower: true,
  switchContentHeight: 0,
  switchContentTop: 0,
  switchVisible: false,
  defaultHeadImage: require("@/src/assets/common/default.png"),
  dropDownTotalStatus: true,
};

const mutations = {
  set_dropDownTotalStatus(state, data) {
    state.dropDownTotalStatus = data;
  },
  set_switchVisible(state, data) {
    state.switchVisible = data;
  },
  set_switchContentHeight(state, data) {
    state.switchContentHeight = data;
  },
  set_switchContentTop(state, data) {
    state.switchContentTop = data;
  },
  set_lang(state, data) {
    state.lang = data;
  },
  set_page_platform(state, data) {
    state.page_platform = data;
  },
  setUserInfo(state, data) {
    state.userInfo = data;

    let nameLs = data.givenName || data.uniqueName;

    state.userName = nameLs;
  },
  userLogout() {
    state.userInfo = {};
  },
  setPower(state, data) {
    state.hasPower = data;
  },
};

const actions = {
  async userModule({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.userModule, {
      params: data,
    });

    return res;
  },
  async userInfo({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.userInfo, {
      params: data,
    });

    if (res.data && res.data.data) {
      commit("setUserInfo", res.data.data);
    }

    return res;
  },
  async logout({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.logout, {
      params: data,
    });

    commit("userLogout");

    return res;
  },
  async userInfoApp({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.userInfoApp, {
      params: data,
    });

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
