const state = {};

const actions = {
  async milesReportMemberList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.milesReportMemberList, {
      params: data,
    });

    return res;
  },
  async reportCoverDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.reportCoverDetail + "/" + data
    );

    return res;
  },
  async reportMiles({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.reportMiles + "/" + data);

    return res;
  },

  async reportBirthdayDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.reportBirthdayDetail + data
    );
    return res;
  },
  async reportBirthdayCurrentEmp({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.reportBirthdayCurrentEmp,
      {
        params: data,
      }
    );
    return res;
  },
  async reportNew({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.reportNew + "/" + data);
    return res;
  },
};

const mutations = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
