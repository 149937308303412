/*
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-02-06 11:33:15
 * @LastEditors: Eric
 * @LastEditTime: 2024-04-23 20:48:24
 * @FilePath: \edp-web\src\languages\lang.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const lang = {
  cn: {
    home: require("./home/cn.json"),
    departmentList: require("./departmentList/cn.json"),
    departmentDetail: require("./departmentDetail/cn.json"),
    member: require("./member/cn.json"),
    rank: require("./rank/cn.json"),
    ability: require("./ability/cn.json"),
    milepostReport: require("./milepostReport/cn.json"),
    reportCover: require("./reportCover/cn.json"),
    edpBackendCommon: require("./edpBackendCommon/cn.json"),
    edpBackendReport: require("./edpBackendReport/cn.json"),
    edpBackendEntry: require("./edpBackendEntry/cn.json"),
    edpBackendRole: require("./edpBackendRole/cn.json"),
    edpBackendPower: require("./edpBackendPower/cn.json"),
    edpBackendMenu: require("./edpBackendMenu/cn.json"),
    crowd: require("./crowd/cn.json"),
    reportApp: require("./reportApp/cn.json"),
    moment: require("./moment/cn.json"),
    workshipRelation: require("./workshipRelation/cn.json"),
    birthdayReport: require("./birthdayReport/cn.json"),
    edpBackendRank: require("./edpBackendRank/cn.json"),
    questionnaire: require("./questionnaire/cn.json"),
    questionnaireTemplate: require("./questionnaireTemplate/cn.json"),
  },
  en: {
    home: require("./home/en.json"),
    departmentList: require("./departmentList/en.json"),
    departmentDetail: require("./departmentDetail/en.json"),
    member: require("./member/en.json"),
    rank: require("./rank/en.json"),
    ability: require("./ability/en.json"),
    milepostReport: require("./milepostReport/en.json"),
    reportCover: require("./reportCover/en.json"),
    edpBackendCommon: require("./edpBackendCommon/en.json"),
    edpBackendReport: require("./edpBackendReport/en.json"),
    edpBackendEntry: require("./edpBackendEntry/en.json"),
    edpBackendRole: require("./edpBackendRole/en.json"),
    edpBackendPower: require("./edpBackendPower/en.json"),
    edpBackendMenu: require("./edpBackendMenu/en.json"),
    crowd: require("./crowd/en.json"),
    reportApp: require("./reportApp/en.json"),
    moment: require("./moment/en.json"),
    workshipRelation: require("./workshipRelation/en.json"),
    birthdayReport: require("./birthdayReport/en.json"),
    edpBackendRank: require("./edpBackendRank/en.json"),
    questionnaire: require("./questionnaire/en.json"),
    questionnaireTemplate: require("./questionnaireTemplate/en.json"),
  },
};

export default lang;
