/*
 * @Author: Eric
 * @Date: 2024-04-12 10:13:12
 * @LastEditors: Eric
 * @LastEditTime: 2024-04-12 10:43:24
 * @Description: file content
 */
import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store/store";

import utils from "./common/utils";
import "./styles/reset.scss";

import Vant from "vant";
import { Lazyload } from "vant";
import "vant/lib/index.css";
import "swiper/dist/css/swiper.css";
import ElementUI from "element-ui";
import localeEn from 'element-ui/lib/locale/lang/en'
import localeCh from 'element-ui/lib/locale/lang/zh-CN'
// import "element-ui/lib/theme-chalk/index.css";

import "./styles/backend.scss";

import VueAwesomeSwiper from "vue-awesome-swiper";

import install from "./components/common/install";
Vue.use(install);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import lang from "./languages/lang";

import Video from "video.js";
import "video.js/dist/video-js.css";
// 引入video.js
Vue.prototype.$video = Video;

const i18n = new VueI18n({
  locale: "en",
  messages: {
    cn: lang.cn,
    en: lang.en,
  },
});

import axios from "axios";
Vue.prototype.$axios = axios;

Vue.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */);

// Vue.prototype.vConsole = vConsole;

Vue.use(Vant);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.config.productionTip = false;
Vue.prototype.utils = utils;
console.info(sessionStorage.lang, `sessionStorage.lang`);
Vue.use(ElementUI, { locale: sessionStorage.lang === 'cn' ? localeCh : localeEn });
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
