const state = {};

const mutations = {};

const actions = {
  async enterList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.module, {
      params: data,
    });

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
