/*
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-04-14 10:27:26
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-07-03 14:09:02
 * @FilePath: \edp-web\src\store\pages\moment.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const state = {};

const mutations = {};

let baseApi = process.env.NODE_ENV === "development" ? "api" : "";

const actions = {
  async getPromote({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.momentPromote, {
      params: data,
    });
    return res.data;
  },
  async getReturn({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.momentReturn, {
      params: data,
    });
    return res.data;
  },
  async getBirthday({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.momentBirthday, {
      params: data,
    });
    return res.data;
  },
  async getCurrent({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.momentCurrent, {
      params: data,
    });
    return res.data;
  },
  async getService({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.momentService, {
      params: data,
    });
    return res.data;
  },
  async getTalentList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.talentList, {
      params: data,
    });
    return res.data;
  },
  async getTalentDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.talentList + "/" + data);
    return res.data;
  },
  async getAchievements({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.achievements + "/" + data
    );
    return res.data;
  },
  async getSalary({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.salary + "/" + data);
    return res.data;
  },
  async getProjectExp({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.projectExp + "/" + data);
    return res.data;
  },
  async getPosition({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.position + "/" + data);
    return res.data;
  },
  async getBonusTrend({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.bonusTrend + "/" + data);
    return res.data;
  },
  async getTotal({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.total + "/" + data);
    return res.data;
  },
  async getBenchmark({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.benchmark + "/" + data);
    return res.data;
  },
  async getBenchmarkTotal({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.benchmarkTotal + "/" + data
    );
    return res.data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
