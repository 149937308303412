<template>
  <div class="edp-header" ref="headerContent">
    <span class="edp-header__left" @click="backClick" v-if="headerLeftWord">
      <img src="@/src/assets/common/icon-left-white.png" />
      {{ headerLeftWord }}
    </span>

    <section class="edp-header__center">
      <img src="@/src/assets/common/logo-group-white.png" @click="goHome" />
    </section>

    <span class="edp-header__right">
      <lang-change />
    </span>
  </div>
</template>

<script>
export default {
  props: ["headerLeftWord", "backOther"],
  name: "edpHeader",
  data() {
    return {};
  },
  methods: {
    goHome() {
      if (this.$route.name === "home") {
        this.$store.commit("login/set_switchVisible", false);
      } else {
        this.$router.push({ name: "home" });
      }
    },
    backClick() {
      if (this.backOther) {
        this.$emit("back");
      } else {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    let _this = this;

    setTimeout(() => {
      let windowHeight = parseInt($(window).height());

      _this.blankHeight = _this.$refs.headerContent.clientHeight + "px";
      _this.$store.commit(
        "login/set_switchContentHeight",
        windowHeight - _this.$refs.headerContent.clientHeight + 1
      );

      _this.$store.commit(
        "login/set_switchContentTop",
        _this.$refs.headerContent.clientHeight - 1
      );
    }, 0);

    $(window).on("resize", function () {
      if (_this.$refs.headerContent) {
        let windowHeight = parseInt($(window).height());

        _this.blankHeight = _this.$refs.headerContent.clientHeight + "px";

        _this.$store.commit(
          "login/set_switchContentHeight",
          windowHeight - _this.$refs.headerContent.clientHeight + 1
        );

        _this.$store.commit(
          "login/set_switchContentTop",
          _this.$refs.headerContent.clientHeight - 1
        );
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-header {
  position: relative;
  width: 100%;
  height: toPc(56);
  padding: 0 toPc(34);
  font-size: toPad(14);
  margin: 0 auto;
  flex-shrink: 0;
  background-image: url("../../assets/common/header-bg.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.edp-header__left {
  color: #fff;
  display: inline-flex;
  position: absolute;
  left: toPad(26);
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  line-height: 1;
  cursor: pointer;
  z-index: 2;
  white-space: nowrap;

  img {
    display: block;
    width: auto;
    height: toPad(12);
    margin-right: toPad(12);
  }
}
.edp-header__center {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;

  img {
    display: block;
    width: auto;
    height: toPc(30);
    flex-shrink: 0;
  }
}
.edp-header__right {
  display: inline-block;
  position: absolute;
  right: toPad(26);
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;
}
</style>
