/*
 * @Author: Eric
 * @Date: 2024-04-02 16:11:43
 * @LastEditors: Eric
 * @LastEditTime: 2024-04-26 16:02:06
 * @Description: file content
 */
const state = {};

const mutations = {};

const actions = {
  async templateList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.templateList, {
      params: data,
    });
    return res.data;
  },
  async publishQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      rootState.api.surveyList + "/" + data.id + "/online",
      data
    );
    return res.data;
  },
  async surveyView({ rootState, commit, state }, data) {
    let path = rootState.api.surveyList + "/" + data.id;
    if (data.param && data.param.answerId) {
      path = `${rootState.api.surveyList}/${data.id}/answer/${data.param.answerId}`;
    }
    let res = await rootState.axios.get(path, {});
    return res.data;
  },
  async templateDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(`${rootState.api.templateList}/${data.id}`);
    return res.data;
  },
  async turnoffTemplate({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.templateList + "/" + data.id + "/turn-off",
      data
    );
    return res.data;
  },
  async turnonTemplate({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.templateList + "/" + data.id + "/turn-on",
      data
    );
    return res.data;
  },
  async templateDelete({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(
      rootState.api.templateList + "/" + data
    );
    return res.data;
  },
  async shareQrcode({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      `${rootState.api.surveyList}/qrcode/${data.id}`
    );
    return res.data;
  },
  async shareRecordList({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      `${rootState.api.surveyList}/shareRecord/list`, data
    );
    return res.data;
  },
  async shareRecordExport({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      `${rootState.api.surveyList}/shareRecord/export`, data, { responseType: "blob", 'Content-Type': 'application/x-download', }
    );
    return res.data;
  },
  async shareUserTree({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(`${rootState.api.surveyList}/user/tree`);
    return res.data;
  },
  async shareSurveyNotice({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      `${rootState.api.surveyNoticeApi}`, data
    );
    return res.data;
  },
  async evaluationDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      `${rootState.api.surveyList}/evaluation/detail/${data.id}`
    );
    return res.data;
  },
  async gapFilling({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      `${rootState.api.surveyList}/evaluation/gap-filling`, { params: data, }
    );
    return res.data;
  },
  async templateCreateSurvey({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(`${rootState.api.templateList}/create/${data.id}`);
    return res.data;
  },
  async templateDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(`${rootState.api.templateList}/${data.id}`);
    return res.data;
  },
  async addSurvey({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.surveyList, data);
    return res.data;
  },
  async editQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id,
      data
    );
    return res.data;
  },
  async offlineQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/offline",
      data
    );
    return res.data;
  },
  async onlineQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/online",
      data
    );
    return res.data;
  },
  async turnoffQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/turn-off",
      data
    );
    return res.data;
  },
  async turnonQuestion({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.surveyList + "/" + data.id + "/turn-on",
      data
    );
    return res.data;
  },

  async surveyDelete({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(
      rootState.api.surveyList + "/" + data
    );
    return res.data;
  },
  async surveyCascade({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.surveyList + "/cascade", {
      params: data,
    });
    return res.data;
  },
  async getSurveyCascade({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/cascade/" + data.code,
      {
        params: data,
      }
    );
    return res.data;
  },
  async surveyAnswer({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/" + data.id + "/answer",
      {
        params: data,
      }
    );
    return res.data;
  },
  async surveyAnswerExport({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/" + data.id + "/answer/export",
      {
        responseType: "blob",
      }
    );
    return res.data;
  },
  async surveyAnswerId({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.surveyList + "/" + data.id + "/answer/" + data.answerId,
      {
        params: data,
      }
    );
    return res.data;
  },
  async copySurvey({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(
      `${rootState.api.surveyList}/copy/${data}`
    );
    return res.data;
  },
  async surveyQrcode({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      `${rootState.api.surveyList}/qrcode/${data}`
    );
    return res.data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
