import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
Vue.use(Router);

var router = new Router({
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("views/home"),
      children: [],
    },
    {
      path: "/management",
      name: "management",
      component: () => import("views/management"),
      children: [],
    },
    {
      path: "/memberList",
      name: "memberList",
      component: () => import("views/memberList"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
        {
          path: "talent",
          component: () => import("views/talentGrowth"),
        },
        {
          path: "workshipRelation",
          component: () => import("views/workshipRelation"),
        },
      ],
    },
    {
      path: "/memberDetail",
      name: "memberDetail",
      component: () => import("views/memberDetail"),
      children: [
        {
          path: "memberDetailInside",
          component: () => import("views/memberDetail"),
        },
      ],
    },
    {
      path: "/memberDetailInside",
      name: "memberDetailInside",
      component: () => import("views/memberDetail"),
    },
    {
      path: "/departmentList",
      name: "departmentList",
      component: () => import("views/departmentList"),
      children: [],
    },
    {
      path: "/departmentDetail",
      name: "departmentDetail",
      component: () => import("views/departmentDetail"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
      ],
    },
    {
      path: "/rankingList",
      name: "rankingList",
      component: () => import("views/rankingList"),
      children: [],
    },
    {
      path: "/rankBoard",
      name: "rankBoard",
      component: () => import("views/rankBoard"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
      ],
    },
    {
      path: "/360feedback",
      name: "360feedback",
      component: () => import("views/360feedback"),
      children: [],
    },
    {
      path: "/milepostHome",
      name: "milepostHome",
      component: () => import("views/milepostHome"),
      children: [],
    },
    {
      path: "/abilityList",
      name: "abilityList",
      component: () => import("views/abilityList"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
      ],
    },
    {
      path: "/milepostReport",
      name: "milepostReport",
      component: () => import("views/milepostReport"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
      ],
    },
    {
      path: "/reportCover",
      name: "reportCover",
      component: () => import("views/reportCover"),
      children: [],
    },
    {
      path: "/reportCoverApp",
      name: "reportCoverApp",
      component: () => import("views/reportCoverApp"),
      children: [],
    },
    {
      path: "/reportApp_annual",
      name: "reportApp_annual",
      component: () => import("views/reportCover"),
      children: [],
    },
    {
      path: "/entryReport",
      name: "entryReport",
      component: () => import("views/entryReport"),
      children: [],
    },
    {
      path: "/reportApp_anniversary",
      name: "reportApp_anniversary",
      component: () => import("views/entryReport"),
      children: [],
    },

    {
      path: "/backend-report",
      name: "backend-report",
      component: () => import("views/backend/backend-report"),
      children: [],
    },
    {
      path: "/backend-enter",
      name: "backend-enter",
      component: () => import("views/backend/backend-enter"),
      children: [],
    },
    {
      path: "/backend-user",
      name: "backend-user",
      component: () => import("views/backend/backend-user"),
      children: [],
    },
    {
      path: "/backend-role",
      name: "backend-role",
      component: () => import("views/backend/backend-role"),
      children: [],
    },
    {
      path: "/backend-power",
      name: "backend-power",
      component: () => import("views/backend/backend-power"),
      children: [],
    },
    {
      path: "/backend-menu",
      name: "backend-menu",
      component: () => import("views/backend/backend-menu"),
      children: [],
    },
    {
      path: "/backend-function",
      name: "backend-function",
      component: () => import("views/backend/backend-function"),
      children: [],
    },
    {
      path: "/no-access",
      name: "no-access",
      component: () => import("views/noAccess"),
      children: [],
    },

    {
      path: "/reportApp",
      name: "reportApp",
      component: () => import("views/reportApp"),
      children: [],
    },

    {
      path: "/crowd",
      name: "crowd",
      component: () => import("views/crowd"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
      ],
    },

    {
      path: "/moment",
      name: "moment",
      component: () => import("views/moment"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
      ],
    },
    {
      path: "/talentUser",
      name: "talentUser",
      component: () => import("views/memberList"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
        {
          path: "talent",
          component: () => import("views/talentGrowth"),
        },
        {
          path: "workshipRelation",
          component: () => import("views/workshipRelation"),
        },
      ],
    },
    {
      path: "/talent",
      name: "talent",
      component: () => import("views/talentGrowth"),
      children: [],
    },
    {
      path: "/360webview",
      name: "360webview",
      component: () => import("views/360webview"),
      children: [],
    },
    {
      path: "/relationUser",
      name: "relationUser",
      component: () => import("views/memberList"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
        {
          path: "talent",
          component: () => import("views/talentGrowth"),
        },
        {
          path: "workshipRelation",
          component: () => import("views/workshipRelation"),
          children: [
            {
              path: "memberDetail",
              component: () => import("views/memberDetail"),
              children: [
                {
                  path: "memberDetailInside",
                  component: () => import("views/memberDetail"),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/workshipRelation",
      name: "workshipRelation",
      component: () => import("views/workshipRelation"),
      children: [
        {
          path: "memberDetail",
          component: () => import("views/memberDetail"),
          children: [
            {
              path: "memberDetailInside",
              component: () => import("views/memberDetail"),
            },
          ],
        },
      ],
    },
    {
      path: "/birthdayReport",
      name: "birthdayReport",
      component: () => import("views/birthdayReport"),
      children: [],
    },
    {
      path: "/reportApp_birthday",
      name: "reportApp_birthday",
      component: () => import("views/birthdayReport"),
      children: [],
    },
    {
      path: "/backend-deptment",
      name: "backend-deptment",
      component: () => import("views/backend/backend-deptment"),
      children: [],
    },
    {
      path: "/backend-template",
      name: "backend-template",
      component: () => import("views/backend/backend-template"),
      children: [],
    },
    {
      path: "/backend-category",
      name: "backend-category",
      component: () => import("views/backend/backend-category"),
      children: [],
    },
    {
      path: "/backend-advquery",
      name: "backend-advquery",
      component: () => import("views/backend/backend-advquery"),
      children: [],
    },
    {
      path: "/backend-rank",
      name: "backend-rank",
      component: () => import("views/backend/backend-rank"),
      children: [],
    },
    {
      path: "/backend-medal",
      name: "backend-medal",
      component: () => import("views/backend/backend-medal"),
      children: [],
    },
    {
      path: "/backend-crowdExport",
      name: "backend-crowdExport",
      component: () => import("views/backend/backend-crowdExport"),
      children: [],
    },
    {
      path: "/backend-questionnaire",
      name: "backend-questionnaire",
      component: () => import("views/backend/questionnaire/questionnaires"),
      children: [],
    },
    {
      path: "/backend-addQuestionnaires",
      name: "backend-addQuestionnaires",
      component: () => import("views/backend/questionnaire/addQuestionnaires"),
      meta: {
        activeMenu: "backend-questionnaire",
      },
    },
    {
      path: "/backend-checkQuestionnaires",
      name: "backend-checkQuestionnaires",
      component: () =>
        import("views/backend/questionnaire/checkQuestionnaires"),
      meta: {
        activeMenu: "backend-questionnaire",
      },
    },
    {
      path: "/backend-questionnaire-template",
      name: "backend-questionnaire-template",
      component: () =>
        import("views/backend/questionnaire/questionnaireTemplate"),
      children: [],
    },
    {
      path: "/backend-questionnaire-preview",
      name: "backend-questionnaire-preview",
      component: () => import("views/backend/questionnaire/preview"),
      meta: {
        activeMenu: "backend-questionnaire",
      },
      children: [],
    },
    {
      path: "/backend-questionnaire-publish",
      name: "backend-questionnaire-publish",
      component: () => import("views/backend/questionnaire/publish"),
      meta: {
        activeMenu: "backend-questionnaire",
      },
      children: [],
    },
    {
      path: "/backend-questionnaire-share",
      name: "backend-questionnaire-share",
      component: () => import("views/backend/questionnaire/share"),
      meta: {
        activeMenu: "backend-questionnaire",
      },
      children: [],
    },
    {
      path: "/backend-questionnaire-dashboard",
      name: "backend-questionnaire-dashboard",
      component: () => import("views/backend/questionnaire/dashboard"),
      meta: {
        activeMenu: "backend-questionnaire",
      },
      children: [],
    },
    {
      path: "/backend-template-detail",
      name: "backend-template-detail",
      component: () => import("views/backend/questionnaire/templateDetail"),
      meta: {
        activeMenu: "backend-questionnaire-template",
      },
      children: [],
    },
    {
      path: "/annualReport",
      name: "annualReport",
      component: () => import("views/annualReport"),
    },
  ],
});

router.afterEach((to, from, next) => {
  store.commit("backendMenu/setMenuActive", to.name);
});

export default router;
