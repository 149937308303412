var apiList = {
  userInfo: "user/me",
  logout: "logout",
  userList: "user",
  userSync: "user/sync-ad-user",
  userMenu: "user/menu",
  module: "module",
  function: "function",
  department: "department",
  template: "edp-report-temp",
  dicType: "dicType",
  queryCategory: "query-field-category",
  queryField: "query-field",
  fieldTable: "query-field/table-list",
  fieldColumn: "query-field/table-column-list",
  medal: "edp-medal-info",
  userModule: "user/module",

  role: "role",
  roleRefresh: "role/refresh-cache",
  power: "permission",
  menu: "menu",
  menuTree: "menu/tree",
  recommendList: "crowd-pack-emp-record/recommend-list",
  crowdList: "crowd-pack-emp-record/list",
  crowdExport: "crowd-pack-emp-record/export",
  setCrowd: "crowd-pack-emp-record/recommend/is-enabled-record/",

  report: "evaluation-report",
  reportDetelte: "evaluation-report/deleted",
  reportHistory: "evaluation-report/upload-record",
  reportHistoryDetail: "evaluation-report/upload-record/detail",
  reportUpload: "evaluation-report/upload",
  reportCheck: "evaluation-report/upload-sign",

  empInfo: "report/emp",
  rewardInfo: "report/emp/punish-reward/",
  projectInfo: "report/emp/project-undergo/",
  jobInfo: "report/emp/job-undergo/",
  jobStatus: "report/emp/job-status/",
  jobJlr: "report/emp/jlr/",
  evalReprort: "report/emp/evaluation-report/",
  subList: "report/emp/subordinate/",
  perform: "report/emp/perform-review/",
  fbUsers: "report/valuation",

  abilityList: "report/emp-evaluation",
  abilityView: "report/emp-evaluation/preview",
  abilityDown: "report/emp-evaluation/download",

  rank_meetTime: "report/rank/meeting-time",
  rank_printNum: "report/rank/print-num",
  rank_meetCancel: "report/rank/meet-room-cancel",
  rank_taxi: "report/rank/taxi-fare",
  rank_edcMoney: "report/rank/edc-fare",
  rank_phoneMoney: "report/rank/telephone-fare",
  rank_travelInCountry: "report/rank/domestic-travel",
  rank_travelOutCountry: "report/rank/foreign-travel",

  rank_inPush: "report/rank/internal-push",
  rank_workYear: "report/rank/work-year",
  rank_studyNums: "report/rank/study-nums",

  rank360_member: "report/valuation/emp-rank",
  rank360_depart: "report/valuation/dept-rank",

  yearUsers: "report/year/year-end",
  anyUsers: "report/year/anniversary",
  reportCoverDetail: "report/year/year-end",
  reportMiles: "report/year/milepost",

  outputImage: "evaluation-report/thumbnail",
  memberReportDetail: "report/emp-evaluation/details",

  milesReportMemberList: "report/year/emp-list",

  //生日祝福
  reportBirthdayDetail: "report/year/birthday/",
  reportBirthdayCurrentEmp: "report/year/birthday/current-emp",
  //员工时刻
  momentPromote: "report/emp-moments/promote",
  momentReturn: "report/emp-moments/return",
  momentBirthday: "report/emp-moments/birthday",
  momentCurrent: "report/emp-moments/current-month-emp",
  momentService: "report/emp-moments/long-term-service",
  //人才成长
  talentList: "report/emp-talent-growth",
  achievements: "report/emp-talent-growth/achievements",
  salary: "report/emp-talent-growth/salary-increase",
  projectExp: "report/emp-talent-growth/project-exp",
  position: "report/emp-talent-growth/position",
  bonusTrend: "report/emp-talent-growth/bonus-trend",
  total: "report/emp-talent-growth/revenue-growth",
  benchmark: "report/emp-talent-growth/industry-average-salary",
  benchmarkTotal: "report/emp-talent-growth/industry-average-revenue",

  hrPrice: "report/rank/hr-expense",
  daliyPrice: "report/rank/daily-expense",
  travelPrice: "report/rank/travel-expense",
  leaveRank: "report/rank/leave-days",
  workPlacePercent: "report/rank/office-rate",

  empWorkRelation: "report/emp-work-relation",
  workRelationMap: "report/emp-work-relation/map/",
  colleagueCount: "report/emp-work-relation/map/colleague-count/",
  colleagueList: "report/emp-work-relation/map/colleague-list",

  defaultBagList: "crowd-pack/packType",
  bagCond1: "query-field/queryField",

  createMyBag: "crowd-pack/insert",
  //人群画像
  crowdPortrait: "crowd-pack/portrait",
  bagMemberList: "crowd-pack/empList",
  bagMemberExport: "crowd-pack/export",

  myBagList: "crowd-pack/my",
  updateMyBag: "crowd-pack/updata",
  deleteMyBag: "crowd-pack/delete",
  myBagDetail: "crowd-pack",

  appReportList: "report/year/milepost/current-emp",
  appReportAnDetail: "report/year/year-end/current-emp",

  rank_backend: "rank",
  rank_front: "report/rank/title",

  userInfoApp:
    process.env.VUE_APP_APPLOCAL +
    "emplapp-notice/jlrc-integration/sso/v1/user-info",
  reportNew: "report/year/year-end/h5",

  userPermitListApi: "user/org/permission",
  userPermitUpdateApi: "user/org",
  userSyncDepartData: "user/sync-app-org",

  surveyList: "admin/survey",
  surveyH5: "anons/api/survey",

  deptPermitListApi: "department/report/permission",
  deptPermitUpdateApi: "department/report/permission/edit",
  deptUserRptPermitCheck: "department/user/permission",

  userRptPermitCheck: "user/permission",

  // questionnaire template
  templateList: "admin/survey-template",
  // share
  surveyNoticeApi: "mail/surveyNotice",
};

var beforeSign = "";

process.env.NODE_ENV === "development"
  ? (beforeSign = "api/")
  : (beforeSign = "");

for (var key in apiList) {
  if (key != "userInfoApp") {
    let oldval = apiList[key];

    apiList[key] = beforeSign + "" + oldval;
  }
}

export default apiList;
