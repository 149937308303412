import edpHeader from "./edpHeader.vue";
import edpHeaderSearch from "./edpHeaderSearch.vue";
import edpBackendMenu from "./edpBackendMenu.vue";
import mainTitle from "./mainTitle.vue";
import edpBackendUser from "./edpBackendUser.vue";
import langChange from "./langChange.vue";
import switchModeContent from "./switchModeContent.vue";
import frontDropDown from "./frontDropDown.vue";
import edpDialog from "./edpDialog.vue";
import edpWaterBg from "./edpWaterBg.vue";
import noPermit from "./noPermit.vue";

const component = {
  install: function (Vue) {
    Vue.component("edpHeader", edpHeader);
    Vue.component("edpHeaderSearch", edpHeaderSearch);
    Vue.component("edpBackendMenu", edpBackendMenu);
    Vue.component("mainTitle", mainTitle);
    Vue.component("edpBackendUser", edpBackendUser);
    Vue.component("langChange", langChange);
    Vue.component("switchModeContent", switchModeContent);
    Vue.component("frontDropDown", frontDropDown);
    Vue.component("edpDialog", edpDialog);
    Vue.component("edpWaterBg", edpWaterBg);
    Vue.component("noPermit", noPermit);
  },
};

export default component;
