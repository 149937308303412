<template>
  <van-dialog
    :showConfirmButton="showConfirmButton || false"
    :showCancelButton="showCancelButton || false"
    v-model="dialogShowPage"
    :close-on-click-overlay="closeOnClickOverlay || true"
    :width="width || 680"
    :overlay="overlayShow && overlayShow === 'no' ? false : true"
    class="edp-common-dialog"
  >
    <div class="edp-common-dialog__title">
      <div class="edp-common-dialog__title__title">
        <span v-if="title">{{ title }}</span>
      </div>

      <div class="edp-common-dialog__title__control">
        <slot name="control"></slot>
      </div>

      <span class="edp-common-dialog__close" @click="closeFn"></span>
    </div>

    <div class="edp-common-dialog__content">
      <slot name="bgContent"></slot>
      <slot name="content"></slot>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "edpDialog",
  props: [
    "title",
    "showConfirmButton",
    "showCancelButton",
    "dialogShow",
    "closeOnClickOverlay",
    "width",
    "overlayShow",
  ],
  data() {
    return {
      dialogShowPage: false,
    };
  },

  methods: {
    show() {
      this.dialogShowPage = true;
    },
    closeFn() {
      this.dialogShowPage = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-common-dialog {
  max-width: 100%;
  padding: toPad(20);
  border-radius: toPad(6);
  top: 50%;

  .edp-common-dialog__title {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: toPad(16);
    z-index: 10;

    .edp-common-dialog__title__title {
      flex-shrink: 0;
    }
    .edp-common-dialog__title__control {
      flex-basis: 100%;
    }
    .edp-common-dialog__close {
      flex-shrink: 0;
      width: toPad(17);
      height: toPad(17);
      background: url("~@/src/assets/common/close.png") no-repeat 0 0;
      background-size: cover;
      cursor: pointer;
    }
  }
}
</style>
