const state = {
  rankTopSelected: 0,

  memberDepartSelected: "member",
};

const actions = {
  async rank_meetTime({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_meetTime, {
      params: data,
    });

    return res;
  },
  async rank_printNum({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_printNum, {
      params: data,
    });

    return res;
  },
  async rank_meetCancel({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_meetCancel, {
      params: data,
    });

    return res;
  },
  async rank_taxi({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_taxi, {
      params: data,
    });

    return res;
  },
  async rank_edcMoney({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_edcMoney, {
      params: data,
    });

    return res;
  },
  async rank_phoneMoney({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_phoneMoney, {
      params: data,
    });

    return res;
  },
  async rank_travelInCountry({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_travelInCountry, {
      params: data,
    });

    return res;
  },
  async rank_travelOutCountry({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_travelOutCountry, {
      params: data,
    });

    return res;
  },

  async rank_inPush({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_inPush, {
      params: data,
    });

    return res;
  },
  async rank_workYear({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_workYear, {
      params: data,
    });

    return res;
  },
  async rank_studyNums({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_studyNums, {
      params: data,
    });

    return res;
  },
  async rank360_member({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank360_member, {
      params: data,
    });

    return res;
  },
  async rank360_depart({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank360_depart, {
      params: data,
    });

    return res;
  },
  async hrPrice({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.hrPrice, {
      params: data,
    });

    return res;
  },
  async daliyPrice({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.daliyPrice, {
      params: data,
    });

    return res;
  },
  async travelPrice({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.travelPrice, {
      params: data,
    });

    return res;
  },
  async leaveRank({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.leaveRank, {
      params: data,
    });

    return res;
  },
  async workPlacePercent({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.workPlacePercent, {
      params: data,
    });

    return res;
  },
  async rank_front({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_front, {
      params: data,
    });

    return res;
  },
  async rank_activeApi({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(data.url, {
      params: data.params,
    });

    return res;
  },
};

const mutations = {
  set_rankTopSelected(state, data) {
    state.rankTopSelected = data;
  },
  set_memberDepartSelected(state, data) {
    state.memberDepartSelected = data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
