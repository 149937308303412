import router from "@/src/router";

const state = {
  menu: [],
  menuActive: {
    cnName: "",
    enName: "",
    index: "",
    urlName: "",
    totalEnName: "",
    totalCnName: "",
  },
};

function findActive(list, data) {
  const routes = router.options.routes || [];
  list.some((val) => {
    const currentRoute = routes.find(item => item.name === data);
    if (val.menuLinkUrl === data) {
      state.menuActive["cnName"] = val.menuCnName;
      state.menuActive["enName"] = val.menuEnName;
      state.menuActive["index"] = val.menuIndex;
      state.menuActive["totalEnName"] = val.totalEnName;
      state.menuActive["totalCnName"] = val.totalCnName;

      return true;
    }
    if (currentRoute && currentRoute.meta && currentRoute.meta.activeMenu === val.menuLinkUrl) {
      // state.menuActive["cnName"] = val.menuCnName;
      // state.menuActive["enName"] = val.menuEnName;
      // state.menuActive["index"] = val.menuIndex;
      // state.menuActive["totalEnName"] = val.totalEnName;
      // state.menuActive["totalCnName"] = val.totalCnName;

      // return true;
    }

    if (val.children && val.children.length > 0) {
      findActive(val.children, data);
    }
  });
}

const mutations = {
  setMenuActive(state, data) {
    state.menuActive["urlName"] = data;

    findActive(state.menu, data);

    if (state.menu && state.menu.length > 0) {
      findActive(state.menu, data);
    } else {
      let sst = setInterval(() => {
        if (state.menu && state.menu.length > 0) {
          clearInterval(sst);
          findActive(state.menu, data);
        }
      }, 500);
    }
  },
  setMenuState(state, data) {
    let menuOptions = JSON.parse(JSON.stringify(data));

    menuOptions.forEach((val, e) => {
      val.menuIndex = e + 1 + "";
      val.totalCnName = val.menuCnName;
      val.totalEnName = val.menuEnName;
    });

    function menuListInit(data) {
      let pageLs = data;
      pageLs.forEach((val, e) => {
        val.urlName = val.menuLinkUrl;
        if (val.children && val.children.length > 0) {
          val.children.forEach((val2, e2) => {
            val2.menuIndex = val.menuIndex + "-" + (e2 + 1);
            val2.totalCnName = val.menuCnName + " / " + val2.menuCnName;
            val2.totalEnName = val.menuEnName + " / " + val2.menuEnName;
          });

          menuListInit(val.children);
        }
      });
    }

    menuListInit(menuOptions);

    state.menu = menuOptions;
  },
};

const actions = {
  async menuUsr({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.userMenu, {
      params: data,
    });

    commit("setMenuState", res.data.data);

    return res;
  },
  async menuList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.menuTree, {
      params: data,
    });

    return res;
  },
  async menuListSet({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.menuTree, {
      params: data,
    });

    commit("setMenuState", res.data.data);

    return res;
  },
  async menuCreate({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.menu, data);

    return res;
  },
  async menuUpdate({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.menu + "/" + data.id,
      data
    );

    return res;
  },
  async menuDelete({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(rootState.api.menu + "/" + data);

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
