import Base64 from "crypto-js/enc-base64";
import HmacSHA256 from "crypto-js/hmac-sha256";
import MD5 from "crypto-js/md5";
import uuid from "./uuid";

var appKey = "edp-web";
var appSecret = process.env["VUE_APP_APIKEY"];

const SIGN_APP_KEY_NAME = "x-ca-app-key";

const SIGN_TIMESTAMP_KEY_NAME = "x-ca-timestamp";

const SIGN_NONCE_KEY_NAME = "x-ca-nonce";

const SIGN_HEADERS_KEY_NAME = "x-ca-signature-headers";

const SIGN_HEAD_KEYS = "x-ca-app-key,x-ca-nonce,x-ca-timestamp";

const REQUEST_BODY_METHOD = ["post", "put"];

const LF = "\n";

const SIGN_VALUE_KEY_NAME = "x-ca-signature";

function initHeader() {
  return {
    [SIGN_APP_KEY_NAME]: appKey,
    [SIGN_TIMESTAMP_KEY_NAME]: Date.now(),
    [SIGN_NONCE_KEY_NAME]: uuid(),
    [SIGN_HEADERS_KEY_NAME]: SIGN_HEAD_KEYS,
  };
}

function sign(reqUri, reqMethod, params, body) {
  reqMethod = reqMethod.toLowerCase();
  // 初始化请求头
  let headers = initHeader();

  // 获取参与签名的HTTP请求头信息
  let headersToSign = buildHeadersToSign(headers);

  // 构建BodyMd5
  let bodyMd5 = buildBodyMd5(reqMethod, body);

  // 组织Uri+请求参数的签名字符串
  let resourceToSign = buildResource(reqUri, params);;
  // 请求参数拼接字符串 明文
  let plaintext = buildStringToSign(
    headersToSign,
    resourceToSign,
    reqMethod,
    bodyMd5
  );
  // 生成签名 密文
  const ciphertext = Base64.stringify(HmacSHA256(plaintext, appSecret));

  headers[SIGN_VALUE_KEY_NAME] = ciphertext;

  return headers;
}

function buildStringToSign(headersToSign, resourceToSign, reqMethod, bodyMd5) {
  let result = "".concat(reqMethod).concat(LF);
  if (bodyMd5) {
    result = result.concat(bodyMd5);
  }
  result = result.concat(LF);

  result = result.concat(buildHeaders(headersToSign));

  result = result.concat(resourceToSign);

  return result;
}

function buildHeaders(headersToSign) {
  let result = "";

  for (let key in headersToSign) {
    result = result
      .concat(key.toLowerCase())
      .concat(":")
      .concat(headersToSign[key])
      .concat(LF);
  }

  return result;
}

function buildHeadersToSign(headers) {
  let headersToSignString = headers[SIGN_HEADERS_KEY_NAME];

  let signKeys = headersToSignString.split(",");

  let obj = {};

  signKeys.forEach(function (item) {
    obj[item] = headers[item];
  });

  return obj;
}

function buildBodyMd5(reqMethod, body) {
  if (!body) {
    return null;
  }
  if (REQUEST_BODY_METHOD.indexOf(reqMethod) < 0) {
    return null;
  }
  return Base64.stringify(MD5(JSON.stringify(body)));
}

function buildResource(reqUri, params) {
  let result = "".concat(reqUri);

  if (params && Object.keys(params).length > 0) {
    result =
      result.indexOf("?") == -1 ? result.concat("?") : result.concat("&");
    result = result.concat(buildMapToSign(params));
  }
  return result;
}

function buildMapToSign(params) {
  let result = "";
  let keys = Object.keys(params);
  keys.sort();
  keys.forEach(function (key) {
    if (result.length > 0) {
      result = result.concat("&");
    }
    result = result.concat(key).concat("=").concat(params[key]);
  });
  return result;
}

export { sign };
