<template>
  <div class="noPermit-common">
    <span :class="{ noWord: noWord }">{{
      $t("edpBackendCommon.noPermssion")
    }}</span>
  </div>
</template>

<script>
export default {
  name: "noPermit",
  props: ["noWord"],
  data() {
    return {
      noWordPage: false,
    };
  },
  methods: {},
  created() {
    this.noWordPage = this.noWord;
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.noPermit-common {
  width: 100%;
  height: calc(100vh - 1.65rem);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: toPad(20);
  font-weight: bold;
  border-radius: toPad(10);
  color: #999;

  .noWord {
    display: none;
  }
}
</style>
