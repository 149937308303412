import axios from "axios";
import { Toast } from "vant";
import router from "../router";
import store from "../store/store";
import { sign } from "../utils/SignUtils";
import { signapp } from "../utils/SignApp";

var xslxList = ["report/emp-evaluation/download"];

let whiteList = ["emplapp-notice/jlrc-integration/sso/v1/user-info"];

if (typeof String.prototype.startsWith != "function") {
  String.prototype.startsWith = function (prefix) {
    return this.slice(0, prefix.length) === prefix;
  };
}

axios.defaults.baseURL = process.env.VUE_APP_BASE_PATH;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const { url } = config;

    let urlSign =
      process.env.NODE_ENV === "development"
        ? url.replace("api/", "")
        : url.replace("api/", "").replace(process.env.VUE_APP_BASE_PATH, "");

    if (!urlSign.startsWith("/")) {
      urlSign = "/" + urlSign;
    }

    let someStatus = whiteList.some((val) => {
      return url.indexOf(val) != -1;
    });

    let signObj = {};

    if (someStatus) {
      config.headers["Content-Type"] = "application/json";

      signObj = signapp(config);
    } else {
      signObj = sign(urlSign, config.method, config.params, config.data);
    }

    config.headers["X-Content-Language"] =
      store.state.login.lang === "cn" ? "zh_CN" : "en_US";

    if (someStatus) {
      config.headers["ss-id-token"] =
        "Bearer " +
        (process.env.NODE_ENV === "development"
          ? "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJlbXBsYXBwIiwic3ViIjoiMTYyODI5MzU4NzY2NzQ2MDA5OCIsImlzcyI6IkVNUCIsImFwcEtleSI6ImVtcCIsInR5cCI6IkJlYXJlciIsImV4cCI6MTcwODE1ODc4MSwiaWF0IjoxNzA1NTY2NzgxLCJwbGF0Zm9ybSI6Imlvcy9hbmRyb2lkIiwianRpIjoiRU1QMTc0Nzg5OTg4NDk2NDU2OTA5MCJ9.Dna6I1YNoO3axNRsZVwN3tas-awWg6x45M2tUqW1PJksjVemHjfz6yLn9OLKVH-a4b6CkoI8Uo7o9a1DMHNLpFuSlpz3njwQCenqGH1yBt2Q6qU46R9F1j0GnzQe2rAhqhLRwbGwzARbl_WAAIz94FagCIaHNsE6q8TtBTBJxfM0ZcKKhF1f8KifAfXjmrfrUW1jWxu5k6w_DyZp56gx92beqBm7nXKz_OYkecjuiGcnXEZnPq2MmAXERvCyHxbSGqOq2YDty4awXth_AXt0QRCJ6j-wngxv0e2oP9A5duVPMjLYoCZCikRmucr7S7iwTYAxdXaccRCX5y8lg2wauw"
          : localStorage.appToken);
    }

    if (someStatus) {
      config.headers = signObj;
    } else {
      config.headers = Object.assign(config.headers, signObj);
    }

    let xslxStatus = xslxList.some((valX) => {
      return url.includes(valX);
    });

    if (xslxStatus) {
      config.responseType = "blob";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data.code === "100004") {
      window.location.href = encodeURI(process.env.VUE_APP_LOGIN);
    } else {
      if (response.data && !response.data.success) {
        if (
          response.data.message ||
          (response.data.msg && response.data.msg.indexOf("success") == -1)
        ) {
          Toast(response.data.message || response.data.msg);
        }
      }
    }

    if (!store.state.login.hasPower) {
      router.push({ path: "/no-access" });
    }

    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.data) {
      Toast(response.data.message || response.data.msg || "Under construction");
    }

    return Promise.reject(error);
  }
);

export default axios;
