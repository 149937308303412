<template>
  <div
    class="edp-langChange"
    :class="{ 'edp-langChange--pc': mode && mode === 'pc' }"
  >
    <div class="edp-langChange__comp" @click="switchShow">
      {{ $t("home.changeMode") }}
    </div>

    <span class="edpBackendUser-info-image" @click="showDialog">{{
      userName && userName.length > 0 ? userName.split("")[0] : ""
    }}</span>

    <div class="edpBackendUser-info-control">
      <div class="edpBackendUser-info-userName" @click="showDialog">
        {{ userName }}
      </div>

      <section
        class="edpBackendUser-info-dialog"
        v-show="dialogVisible"
        @click="showDialog"
      >
        <div class="edpBackendUser-info-dialog__top">
          <div class="edpBackendUser-info-dialog__zm">
            {{ userName && userName.length > 0 ? userName.split("")[0] : "" }}
          </div>

          <div class="edpBackendUser-info-dialog__name">{{ userName }}</div>
        </div>

        <dl>
          <dd
            :class="{ selected: this.$i18n.locale === 'cn' }"
            @click="changeLang('cn')"
          >
            中文<img src="@/src/assets/common/select.png" alt="" />
          </dd>
          <dd
            :class="{ selected: this.$i18n.locale === 'en' }"
            @click="changeLang('en')"
          >
            English<img src="@/src/assets/common/select.png" alt="" />
          </dd>
        </dl>

        <div class="edpBackendUser-info-dialog__logout" @click="logoutFn">
          {{ $t("edpBackendCommon.logout") }}
        </div>
      </section>
    </div>

    <!--<em @click="changeLang('en')">EN</em> |
    <em @click="changeLang('cn')">中文</em>

    <span class="edp-langChange-current">
      <span v-if="$i18n.locale === 'cn'">C</span
      ><span v-if="$i18n.locale === 'en'">E</span>N
    </span>

   -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "langChange",
  props: ["mode"],
  data() {
    return {
      name: "",
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.login.userInfo,
      userName: (state) => state.login.userName,
      switchVisible: (state) => state.login.switchVisible,
    }),
  },
  methods: {
    changeLang(data) {
      /*this.$i18n.locale = data;
      this.$store.commit("login/set_lang", data);*/
      sessionStorage.lang = data;

      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    switchShow() {
      this.$store.commit("login/set_switchVisible", !this.switchVisible);
    },
    ...mapActions({
      logout: "login/logout",
    }),
    async logoutFn() {
      // var myOpen = window.open(process.env.VUE_APP_LOGOUT);

      setTimeout(async () => {
        // myOpen.close();
        let res = await this.logout();

        if (res && res.data && res.data.success) {
          window.location.href = process.env.VUE_APP_LOGIN;
        }
      }, 1000);
    },
    showDialog(e) {
      e.stopPropagation();
      this.dialogVisible = true;
      this.$store.commit("login/set_switchVisible", false);
    },
  },
  mounted() {
    let _this = this;

    $(document).on("click", function () {
      _this.dialogVisible = false;
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-langChange {
  position: relative;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  color: #fff;
  font-family: JLREmeric;

  .edp-langChange__comp {
    display: inline-flex;
    font-size: toPad(14);
    cursor: pointer;
    padding: toPad(6) 0 toPad(6) toPad(25);
    background: url("~@/src/assets/common/header-switch-white.png") no-repeat
      left center;
    background-size: toPad(16) toPad(16);
  }
  em {
    position: relative;
    font-size: toPad(14);
    font-style: normal;
    margin: 0 toPad(10);
    cursor: pointer;
    top: toPad(1);
  }
  .edp-langChange-current {
    position: relative;
    display: inline-block;
    font-size: toPad(12);
    padding: toPad(4) toPad(2);
    border: 1px solid #1c1c1e;
    border-radius: 2px;
    transform: scale(0.8);
    top: toPad(1);
    font-weight: bold;

    span {
      color: #3e886d;
    }
  }

  .edpBackendUser-info-image {
    display: flex;
    width: toPad(28);
    height: toPad(28);
    justify-content: center;
    align-items: center;
    font-size: toPad(16);
    background: #797979;
    border-radius: 100%;
    margin-left: toPad(45);
    color: #fff;
    font-family: JLREmeric;
    cursor: pointer;
  }
  .edpBackendUser-info-control {
    position: relative;
    display: flex;
    color: #fff;
  }
  .edpBackendUser-info-userName {
    width: 100%;
    height: toPad(16);
    cursor: pointer;
    padding-right: toPad(18);
    padding-left: toPad(8);
    background: url("~@/src/assets/common/arro.png") no-repeat right center;
    background-size: auto toPad(7);

    font-size: toPad(14);
  }
  .edpBackendUser-info-dialog {
    position: absolute;
    width: toPad(260);
    background: #fff;
    z-index: 12;
    right: 0;
    top: toPad(-10);
    border-radius: toPad(10);
    box-shadow: 0 toPad(2) toPad(5) #ddd;

    .edpBackendUser-info-dialog__top {
      display: flex;
      width: 100%;
      padding: toPad(15);
      align-items: center;
      border-bottom: 1px solid #eee;
    }
    .edpBackendUser-info-dialog__zm {
      display: flex;
      width: toPad(46);
      height: toPad(46);
      justify-content: center;
      align-items: center;
      font-size: toPad(25);
      background: #444;
      border-radius: 100%;
      color: #fff;
      flex-shrink: 0;
      font-family: JLREmeric;
    }
    .edpBackendUser-info-dialog__name {
      flex-basis: 100%;
      font-size: toPad(16);
      padding-left: toPad(10);
      line-height: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #797979;
    }

    dl {
      width: 100%;
      padding: toPad(13) toPad(10);
      font-size: toPad(16);
      color: #797979;

      dd {
        display: flex;
        width: 100%;
        height: toPad(33);
        padding: toPad(9) toPad(8);
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin: toPad(8) 0;

        img {
          display: none;
          width: auto;
          height: toPad(8);
        }

        &.selected {
          background: #f0f9f8;

          img {
            display: block;
          }
        }
      }
    }

    .edpBackendUser-info-dialog__logout {
      display: block;
      position: relative;
      max-width: 100%;
      margin: 0 toPad(18);
      padding: toPad(15) 0;
      border-top: 1px solid #eee;
      cursor: pointer;
      font-size: toPad(16);
      color: #797979;
    }
  }

  /*&.edp-langChange--pc {
    em {
      font-size: toPc(14);
      margin: 0 toPc(10);
      top: toPc(1);
    }
    .edp-langChange-current {
      font-size: toPc(12);
      padding: toPc(4) toPc(2);
      top: toPc(1);
    }

    .edpBackendUser-info-image {
      width: toPc(28);
      height: toPc(28);
      font-size: toPc(16);
      margin-left: toPc(45);
    }

    .edpBackendUser-info-control {
      height: toPc(16);
      margin-left: toPc(8);
      padding-right: toPc(18);
      background-size: auto toPc(7);
    }
  }*/
}
</style>
