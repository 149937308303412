import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";

import uuid from "./uuid";

const out_uuidv = uuid();

const outParams = {
  "ss-app-key": "emp",
  "ss-content-md5": null,
  "ss-nonce": out_uuidv,
  "ss-signature-method": "HmacSHA256",
  "ss-timestamp": null,
};

function buildStringToSign(data, data2) {
  let params = {
    "ss-platform": "pc",
    "ss-mgmt-app-key": "mgmt",
    "ss-signature": data,
    "ss-signature-headers":
      "ss-nonce,ss-timestamp,ss-app-key,ss-content-md5,ss-signature-method",
    "content-type": "application/json; charset=UTF-8",
  };

  return Object.assign(params, outParams);
}

function signapp(config) {
  let configNew = config;
  //加签开始
  let url = config.url.replace(process.env.VUE_APP_APPLOCAL, "");
  let nowTime = new Date();

  const dataString = config.data
    ? Base64.stringify(CryptoJS.MD5(JSON.stringify(config.data)))
    : Base64.stringify(CryptoJS.MD5(JSON.stringify(config.data)));
  outParams["ss-content-md5"] = dataString;
  outParams["ss-timestamp"] = nowTime.getTime();
  const dataMethod = config.method;

  const postBodyStr =
    (dataMethod.toLowerCase() === "post" ||
      dataMethod.toLowerCase() === "put") &&
    config.data
      ? "\n"
      : "\n";
  let getBody = [],
    getBodyStr = "";
  if (
    dataMethod.toLowerCase() !== "post" &&
    dataMethod.toLowerCase() !== "put" &&
    config.params
  ) {
    let cfParams = JSON.parse(JSON.stringify(config.params));

    for (let key in cfParams) {
      if (cfParams[key] == undefined) {
        delete cfParams[key];
      } else {
        getBody.push(key);
      }

      getBody.sort();
    }

    getBody.forEach((val, e) => {
      if (e == 0) {
        getBodyStr +=
          "?" +
          (cfParams[val] !== "" &&
          cfParams[val] !== null &&
          !(typeof cfParams[val] === "object" && cfParams[val].length === 0)
            ? val + "=" + cfParams[val]
            : val);
      } else {
        getBodyStr +=
          "&" +
          (cfParams[val] !== "" &&
          cfParams[val] !== null &&
          !(typeof cfParams[val] === "object" && cfParams[val].length === 0)
            ? val + "=" + cfParams[val]
            : val);
      }
    });
  }

  let subArrayLsTopStr = "";

  for (let key in outParams) {
    subArrayLsTopStr = subArrayLsTopStr + key + ":" + outParams[key] + "\n";
  }
  let paramsString =
    dataMethod.toUpperCase() +
    "\n" +
    dataString +
    postBodyStr +
    ((dataMethod.toLowerCase() === "post" ||
      dataMethod.toLowerCase() === "put") &&
    config.data
      ? config.headers["content-type"] + "\n"
      : "\n") +
    "\n" +
    subArrayLsTopStr +
    (url[0] === "/" ? "" : "/") +
    url +
    getBodyStr;
  const paramsString256 = Base64.stringify(
    CryptoJS.HmacSHA256(paramsString, process.env.VUE_APP_SERCRETAPP)
  );

  let subArrayLs = buildStringToSign(paramsString256, dataString);
  for (let key in subArrayLs) {
    configNew.headers[key] = subArrayLs[key];
  }
  //加签结束

  return configNew.headers;
}

export { signapp };
