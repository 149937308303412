<template>
  <div id="app" :class="{ edpPage_pc: page_platform === 'windows' }">
    <div class="common-waterSign" v-if="!isApp">
      <div
        class="waterSign-in"
        v-for="(itemWater, indexWater) in 200"
        :key="'water' + indexWater"
      >
        <span>{{ utils.sercetWaterSign(userInfoMap.cdsid) }}</span>
      </div>
    </div>

    <router-view class="rl-document" v-if="mainViewStatus" />

    <switch-mode-content v-show="switchVisible" ref="switchOut" v-if="!isApp" />

    <div
      class="screenShu-notice"
      v-if="screenShuStatus && windowWidthThisComp > 750 && !isApp"
    >
      <img src="@/src/assets/common/screenShu.png" alt="" />

      <em>{{
        $i18n.locale === "cn"
          ? "请用横屏预览效果更佳"
          : "Please rotate your device for the best experience"
      }}</em>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      screenShuStatus: false,
      windowWidthThisComp: 0,
      isApp: false,

      mainViewStatus: false,
    };
  },

  computed: {
    ...mapState({
      switchVisible: (state) => state.login.switchVisible,
      lang: (state) => state.login.lang,
      page_platform: (state) => state.login.page_platform,
      userInfoMap: (state) => state.login.userInfo,
    }),
  },
  methods: {
    ...mapActions({
      userInfo: "login/userInfo",
      menuUsr: "backendMenu/menuUsr",
      userModule: "login/userModule",
    }),
    screenShuPd() {
      let windowWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      if (windowHeight > windowWidth) {
        this.screenShuStatus = true;
      } else {
        this.screenShuStatus = false;
      }
    },
  },
  async created() {
    localStorage.appToken = "";
    /* eslint-disable */
    if (window.hasOwnProperty("flutter_inappwebview")) {
      this.isApp = true;
    }
    /* eslint-enable */

    // this.isApp = true;

    if (
      sessionStorage.lang &&
      (sessionStorage.lang === "cn" || sessionStorage.lang === "en")
    ) {
      this.$i18n.locale = sessionStorage.lang;
      this.$store.commit("login/set_lang", sessionStorage.lang);
    }

    let platInfo = this.utils.getOsInfo();

    this.$store.commit(
      "login/set_page_platform",
      platInfo.name ? platInfo.name.toLowerCase() : ""
    );

    if (!this.isApp) {
      let res = await this.userInfo();

      let resMenu = await this.menuUsr({ current: 1, size: 100 });

      let resEnter = await this.userModule({ current: 1, size: 1000 });

      let enterYn = resEnter.data.data || [];

      if (!enterYn || enterYn.length == 0) {
        this.$store.commit("login/setPower", false);
        this.$router.push({ name: "no-access" });
      }
    }
  },
  async mounted() {
    let _this = this;

    /* eslint-disable */
    if (window.hasOwnProperty("flutter_inappwebview")) {
      let ssAdl = setInterval(() => {
        if (window.flutter_inappwebview) {
          clearInterval(ssAdl);

          window.flutter_inappwebview
            .callHandler("employee_get_ssIdToken")
            .then((result) => {
              localStorage.appToken = result;

              _this.mainViewStatus = true;
            });
        }
      }, 500);
    } else {
      this.mainViewStatus = true;
    }
    /* eslint-enable */

    this.screenShuPd();

    if (this.lang === "en") {
      $("body").addClass("font-jlr");
    } else {
      $("body").removeClass("font-jlr");
    }

    function windowResize() {
      let windowWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      _this.windowWidthThisComp = windowWidth;

      if (windowWidth > 750 && windowWidth <= 1600) {
        $("html").css(
          "font-size",
          ((windowWidth >= 1200 ? windowWidth : 1200) * 100) / 1366 + "px"
        );
      } else if (windowWidth > 1600) {
        $("html").css(
          "font-size",
          ((windowWidth > 1600 ? windowWidth : 1600) * 100) / 1600 + "px"
        );
      } else {
        $("html").css("font-size", (windowWidth * 100) / 750 + "px");
      }
    }

    windowResize();

    $(window).on("resize", function () {
      windowResize();
      _this.screenShuPd();
    });

    window.addEventListener("orientationchange", function () {
      _this.screenShuPd();
    });
  },
  watch: {
    $route(val, oldVal) {
      window.scrollTo(0, 0);

      this.$nextTick(() => {
        if (!this.isApp) {
          this.$refs.switchOut.scrollToZero();
          this.$store.commit("login/set_switchVisible", false);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

#app {
  position: relative;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  font-weight: normal;

  &.noMinWidth {
    min-width: auto;
  }
}

.screenShu-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  color: #fff;
  background: #b5b5b5;

  img {
    display: block;
    width: toPad(90);
    height: auto;
  }
  em {
    display: block;
    width: 100%;
    text-align: center;
    font-size: toPad(22);
    font-style: normal;
    padding-top: toPad(32);
    font-family: JLREmeric;
  }
  p {
    display: block;
    width: 100%;
    text-align: center;
    font-size: toPad(16);
    font-style: normal;
    padding-top: toPad(33);
    font-family: JLREmeric;
  }
}

.common-waterSign {
  position: fixed;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  color: #ddd;
  z-index: -1;
  opacity: 0.8;
  font-weight: bold;
  overflow: hidden;

  .waterSign-in {
    display: flex;
    width: 10%;
    height: toPad(100);
    float: left;
    font-size: toPad(20);

    font-family: JLREmeric;

    justify-content: center;
    align-items: center;

    span {
      transform: rotate(-30deg);
      transform-origin: center center;
    }
  }
}

@media screen and (max-width: 750px) {
  #app {
    min-width: 320px;
  }
}
</style>
