/*
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-03-13 11:12:13
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-07-28 16:33:49
 * @FilePath: \edp-web\src\store\pages\feedBack.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const state = {};

const mutations = {};

let baseApi = process.env.NODE_ENV === "development" ? "api" : "";

const actions = {
  async fbUser({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.fbUsers, {
      params: data,
    });
    return res.data;
  },
  async empScore({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data + "/score"
    );
    return res.data;
  },
  async received({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data + "/received"
    );
    return res.data;
  },
  async give({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data + "/give"
    );
    return res.data;
  },
  async qurank({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data.id + "/qurank",
      {
        params: data.query,
      }
    );
    return res.data;
  },
  async diagram({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data.id + "/diagram",
      {
        params: data.query,
      }
    );
    return res.data;
  },
  async comments({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data + "/comments"
    );
    return res.data;
  },
  async keywords({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data + "/keywords"
    );
    return res.data;
  },
  async keywordsStat({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data + "/keywords-stat"
    );
    return res.data;
  },
  async commentList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      baseApi + "/report/valuation/" + data.id + "/comment-list",
      {
        params: data.query,
      }
    );
    return res.data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
