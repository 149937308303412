<template>
  <div class="edp-backend-menu">
    <el-menu
      :default-active="defaultActive"
      text-color="#fff"
      :unique-opened="true"
    >
      <template v-for="(item, index) in storeMenu">
        <el-submenu
          :index="item.menuIndex"
          v-if="item.children && item.children.length > 0"
          :key="'menuItemIf' + index"
        >
          <template slot="title">
            <div>
              <i :class="item.icon"></i>
              <span>{{
                $i18n.locale === "cn" ? item.menuCnName : item.menuEnName
              }}</span>
            </div>
          </template>

          <el-menu-item-group>
            <div
              v-for="(item2, index2) in item.children"
              :key="'cdItem' + index2"
            >
              <el-submenu
                v-if="item2.children && item2.children.length > 0"
                :index="item2.menuIndex"
              >
                <template slot="title">
                  <div>
                    <i :class="item2.icon"></i>
                    <span>{{
                      $i18n.locale === "cn"
                        ? item2.menuCnName
                        : item2.menuEnName
                    }}</span>
                  </div>
                </template>

                <el-menu-item
                  v-for="(item3, index3) in item2.children"
                  :key="'cdItem3' + index3"
                  :index="item3.menuIndex"
                  class="subMenuItem-three"
                  @click="linkPath(item3)"
                  >{{
                    $i18n.locale === "cn" ? item3.menuCnName : item3.menuEnName
                  }}</el-menu-item
                >
              </el-submenu>

              <el-menu-item
                v-else
                :index="item2.menuIndex"
                @click="linkPath(item2)"
                class="subMenuItem"
                >{{
                  $i18n.locale === "cn" ? item2.menuCnName : item2.menuEnName
                }}</el-menu-item
              >
            </div>
          </el-menu-item-group>
        </el-submenu>

        <el-menu-item
          :index="item.menuIndex"
          :key="'menuItemElse' + index"
          @click="linkPath(item)"
          v-else
        >
          <i :class="item.icon"></i>
          <span slot="title">{{
            $i18n.locale === "cn" ? item.menuCnName : item.menuEnName
          }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "edpBackendMenu",
  data() {
    return {
      defaultActive: "",
    };
  },
  computed: {
    ...mapState({
      storeMenu: (state) => state.backendMenu.menu,
    }),
  },
  mounted() {
    let routeName = this.$route.name,
      _this = this;
    const currentRoute = this.$route;
    function defaultActiveInit(data) {
      data.some((val, e) => {
        if (val.urlName === routeName) {
          _this.defaultActive = val.menuIndex;
          return true;
        }

        if (currentRoute && currentRoute.meta && currentRoute.meta.activeMenu === val.urlName) {
          _this.defaultActive = val.menuIndex;
          return true;
        }

        if (val.children && val.children.length > 0) {
          defaultActiveInit(val.children);
        }
      });
    }

    if (_this.storeMenu && _this.storeMenu.length > 0) {
      defaultActiveInit(_this.storeMenu);
    } else {
      let sstIn = setInterval(() => {
        if (_this.storeMenu && _this.storeMenu.length > 0) {
          clearInterval(sstIn);
          defaultActiveInit(_this.storeMenu);
        }
      }, 300);
    }
  },
  methods: {
    linkPath(data) {
      let thisRouterName = this.$route.name;

      if (thisRouterName != data.urlName && data.urlName) {
        this.$router.push({ name: data.urlName });
      }
    },
  },
};
</script>

<style lang="scss"></style>
