const state = {};

const mutations = {};

const actions = {
  async powerList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.power, {
      params: data,
    });

    return res;
  },
  async powerCreate({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.power, data);

    return res;
  },
  async powerUpdate({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.power + "/" + data.id,
      data
    );

    return res;
  },
  async powerDelete({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(rootState.api.power + "/" + data);

    return res;
  },
  async powerDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.power + "/" + data);

    return res;
  },

  async functionList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.function, {
      params: data,
    });

    return res;
  },
  async enterList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.module, {
      params: data,
    });

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
