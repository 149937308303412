const state = {
  cond1List: [],
  bagConType: "create",
};

const mutations = {
  set_cond1List(state, data) {
    state.cond1List = data;
  },
  set_bagConType(state, data) {
    state.bagConType = data;
  },
};

const actions = {
  async defaultBagList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.defaultBagList + "/" + data.data,
      { params: data.params }
    );

    return res;
  },
  async bagCond1({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.bagCond1, {
      params: data,
    });

    return res;
  },
  async fieldValueOptionUri({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(data.url, {
      params: data.params,
    });

    return res;
  },
  async createMyBag({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.createMyBag, data);

    return res;
  },
  async crowdPortrait({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.crowdPortrait, data);
    return res;
  },
  async bagMemberList({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.bagMemberList, data);

    return res;
  },
  async bagMemberExport({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.bagMemberExport, data, {
      responseType: "blob",
    });

    return res;
  },
  async myBagList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.myBagList, {
      params: data,
    });

    return res;
  },
  async updateMyBag({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.updateMyBag + "/" + data.id,
      data
    );

    return res;
  },
  async deleteMyBag({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(
      rootState.api.deleteMyBag + "/" + data.id,
      data
    );

    return res;
  },
  async myBagDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.myBagDetail + "/" + data.id,
      {
        params: data,
      }
    );

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
