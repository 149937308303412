const state = {};

const mutations = {};

const actions = {
  async rankList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rank_backend, {
      params: data,
    });

    return res;
  },
  async createRank({ rootState, commit, state }, data) {
    let res = await rootState.axios.post(rootState.api.rank_backend, data);

    return res;
  },
  async updateRank({ rootState, commit, state }, data) {
    let res = await rootState.axios.put(
      rootState.api.rank_backend + "/" + data.id,
      data
    );

    return res;
  },
  async deleteRank({ rootState, commit, state }, data) {
    let res = await rootState.axios.delete(
      rootState.api.rank_backend + "/" + data.id
    );

    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
