const state = {};

const mutations = {};

const actions = {
  async empList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.empInfo, {
      params: data,
    });
    return res.data;
  },
  async empDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.empInfo + "/" + data);
    return res.data;
  },
  async rewardInfo({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.rewardInfo + data.id, {
      params: data.query,
    });
    return res.data;
  },
  async projectInfo({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.projectInfo + data);
    return res.data;
  },
  async jobInfo({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.jobInfo + data);
    return res.data;
  },
  async jobStatus({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.jobStatus + data);
    return res.data;
  },
  async jobJlr({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.jobJlr + data);
    return res.data;
  },
  async evalReprort({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.evalReprort + data.id, {
      params: data.query,
    });
    return res.data;
  },
  async subList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.subList + data);
    return res.data;
  },
  async perform({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.perform + data);
    return res.data;
  },
  async yearUsers({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.yearUsers, {
      params: data,
    });
    return res.data;
  },
  async anyUsers({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.anyUsers, {
      params: data,
    });
    return res.data;
  },
  async anyDetail({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.anyUsers + "/" + data);
    return res.data;
  },
  async empWorkRelation({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.empWorkRelation, {
      params: data,
    });
    return res.data;
  },
  async workRelationMap({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.workRelationMap + data);
    return res.data;
  },
  async colleagueCount({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.colleagueCount + data);
    return res.data;
  },
  async colleagueList({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(rootState.api.colleagueList, {
      params: data,
    });
    return res.data;
  },
  async userRptPermitCheck({ rootState, commit, state }, data) {
    let res = await rootState.axios.get(
      rootState.api.userRptPermitCheck + "/" + data
    );
    return res;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
