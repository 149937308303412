<template>
  <div
    class="switchModeContent"
    :style="
      (switchContentHeight ? 'height:' + switchContentHeight + 'px;' : '') +
      (switchContentTop ? 'top:' + switchContentTop + 'px;' : '')
    "
  >
    <div class="switchModeContent-opacity" @click="switchHide"></div>

    <div class="switchModeContent-content" ref="switchScroll">
      <section class="switchModeContent-content__title">
        {{ $t("home.changeModeTitle") }}
      </section>

      <ul>
        <li
          v-for="(item, index) in pageList"
          :key="'switchItem' + index"
          @click="linkTo(item.modLinkUrl)"
          :class="{ selected: item.selected }"
        >
          <img :src="item.modImgPrefix + '' + item.modImgUrl" alt="" />

          <div
            class="switchModeContent-content__li__title"
            v-html="
              $i18n.locale === 'cn'
                ? item.modCnName || item.modEnName
                : item.modEnName || item.modCnName
            "
          ></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "switchModeContent",
  data() {
    return {
      pageList: [],
    };
  },
  computed: {
    ...mapState({
      switchContentHeight: (state) => state.login.switchContentHeight,
      switchContentTop: (state) => state.login.switchContentTop,
    }),
  },
  methods: {
    ...mapActions({
      userModule: "login/userModule",
    }),
    linkTo(val) {
      let path = "/" + val;

      if (path != this.$route.path) {
        this.$router.push("/" + val);
      }
    },
    scrollToZero() {
      this.$refs.switchScroll.scrollTo(0, 0);
    },
    switchHide() {
      this.$refs.switchScroll.scrollTo(0, 0);

      this.$nextTick(() => {
        this.$store.commit("login/set_switchVisible", false);
      });
    },
    selectNow() {
      if (this.pageList && this.pageList.length > 0) {
        this.pageList.forEach((val) => {
          let modUrlLs = val.modLinkUrl.split("?");

          if ("/" + modUrlLs[0] === this.$route.path) {
            this.$set(val, "selected", true);
          } else {
            this.$set(val, "selected", false);
          }
        });
      }
    },
  },
  async created() {
    let res = await this.userModule({ current: 1, size: 1000 });

    this.pageList = res.data && res.data.data ? res.data.data : [];

    this.selectNow();
  },
  watch: {
    $route(val) {
      this.selectNow();
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.switchModeContent {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 11;
  left: 0;
  top: 0;

  .switchModeContent-opacity {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: #000;
    opacity: 0.4;
  }
  .switchModeContent-content {
    position: absolute;
    width: toPad(374);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    top: 0;
    right: 0;
    z-index: 11;

    .switchModeContent-content__title {
      width: 100%;
      padding: toPad(34) toPad(20) toPad(10) toPad(20);
      font-size: toPad(18);
    }

    ul {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 0 toPad(15) toPad(15) toPad(15);

      li {
        position: relative;
        width: 50%;
        height: toPad(95);
        float: left;
        padding: toPad(15) toPad(5) 0 toPad(5);
        cursor: pointer;

        img {
          display: block;
          position: relative;
          z-index: 0;
          width: 100%;
          height: 100%;
          margin: 0 auto;
          border-radius: toPad(5);
        }
        .switchModeContent-content__li__title {
          position: absolute;
          width: 100%;
          left: toPad(18);
          bottom: toPad(8);
          font-size: toPad(16);
          color: #fff;
          z-index: 1;
          font-family: JLREmeric;
        }

        &.selected {
          img {
            border: toPad(4) solid #61d6cc;
          }
        }
      }
    }
  }
}
</style>
